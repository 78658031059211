/* eslint-disable array-callback-return */
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React from 'react';

import { getObjectId } from './versionUtil';
import ClientBenefitTextInput from 'common/form/ClientBenefitTextInput';

export const VendorQuestions = (props) => {
  const { version, setVersion, isReadOnly } = props;
  const theme = useTheme();
  const vendorQuestions = version.vendorQuestionDetails || [];

  const addVendorQuestion = () => {
    setVersion({ ...version, vendorQuestionDetails: [...vendorQuestions, { tempId: uuidv4(), text: '' }] });
  };

  const changeQuestion = (newQuestionText, id) => {
    const question = vendorQuestions.find((q) => getObjectId(q) === id);
    question.text = newQuestionText;
    setVersion({ ...version, vendorQuestionDetails: vendorQuestions });
  };

  const deleteQuestion = (id) => {
    const questionIdx = vendorQuestions.findIndex((q) => getObjectId(q) === id);
    vendorQuestions.splice(questionIdx, 1);
    setVersion({ ...version, vendorQuestionDetails: [...vendorQuestions] });
  };

  return (
    <>
      <Box p={4}>
        <div className="row-no-grow justify-space-between p2">
          <Typography variant="h5">Vendor Questions</Typography>
          <Button
            color="primary"
            variant="outlined"
            style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}
            onClick={addVendorQuestion}
          >
            Add
          </Button>
        </div>
        <Grid container direction="column" alignItems="center">
          {vendorQuestions.length === 0 && (
            <Grid item xs={3} className="row p2">
              <Typography align="center" variant="h5" color="textSecondary">
                No Vendor Questions Added
              </Typography>
            </Grid>
          )}
          {vendorQuestions.length > 0 &&
          vendorQuestions.reverse().map((question) => {
            const id = getObjectId(question);

            //if (question.text) {
              return (
                <Grid item container key={id} alignItems="center">
                  <Grid item xs={10}>
                    <ClientBenefitTextInput
                      clientVersion={question}
                      masterVersion={question.masterQuestionDetail}
                      versionField="text"
                      label="Question"
                      name="questionText"
                      onChange={(value) => changeQuestion(value, id)}
                      onBlur={(value) => changeQuestion(value, id)}
                      InputProps={{ readOnly: isReadOnly }}
                      validateOnBlur
                      validateOnChange
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {
                      !question.masterQuestionDetail &&
                        <IconButton onClick={() => deleteQuestion(id)} variant="outlined"  style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}>
                          <FontAwesomeIcon color={theme.palette.error.main} icon="trash" />
                        </IconButton>
                    }
                    
                  </Grid>
                </Grid>
              );
            //}
          },
          )}
        </Grid>
      </Box>
    </>
  );
};

VendorQuestions.propTypes = {
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default VendorQuestions;

