const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

// use fetch to download binary documents
export const downloadFile = (url, name) => {
  fetch(url, { credentials: 'include' })
    .then((res) => {
      res.text().then((text) => {
        // steps to success:
        // 1) convert to base 64 ascii
        // 2) append prefix with data:application/pdf;base64, + b64 encoded string
        // 3) use as href
        // 4) set download attribute on <a> tag
        // element must be appended to document for Firefox/Chrome/Safari
        // IE is special as usual, and requires construction of a blob from b64 data
        let b64 = btoa(text);

        // IE
        if (navigator && navigator.msSaveOrOpenBlob) {
          let blob = b64toBlob(b64, 'application/octet-stream');
          navigator.msSaveOrOpenBlob(blob, name);
        } else {
          // not IE
          let a = document.createElement('a');
          a.href = `data:application/octet-stream;base64,${  b64}`;
          a.download = name;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    });
};

const blockedFileUploadExtensions = [
  'zip',
  'rar',
  'gz',
  'tar',
  'docm', 
  'dotm',
  'xlsm',
  'xltm',
  'xlam',
  'exe',
  'sh',
  'bin',
];

// in bytes
export const maxFileUploadSize = 10000000;

export const isFileExtensionBlocked = (ext) => {
  return blockedFileUploadExtensions.indexOf(ext.toLowerCase()) > -1;
};

export const getDataUrlFromFile = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const dataUrl = event.target.result;
      resolve(dataUrl);
    };
  });
};

export const getBlobFromCroppedImage = (image, crop, maxHeight) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const aspectRatio = crop.width/crop.height;

  let canvasHeight = crop.height * scaleY;
  let canvasWidth = crop.width * scaleX;

  if (maxHeight && (canvasHeight > maxHeight)) {
    canvasHeight = maxHeight;
    canvasWidth = Math.round(canvasHeight * aspectRatio);
  }

  canvas.height = canvasHeight;
  canvas.width = canvasWidth;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    canvasWidth,
    canvasHeight,
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg', 1);
  });
};


export default downloadFile;