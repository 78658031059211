import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import SubmittedAuthDetails from 'modules/transferees/submittedAuthDetails/submittedAuthDetails.component';

const styles = (theme) => ({
  
  paper: {
    position: 'absolute',
    top: '10%',
    left: '20%',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    zIndex: '4000',
    width: '75%',
    maxWidth: 'calc(95vw)',
    maxHeight: (window.innerHeight - 200) + 'px !important',
    overflow: 'scroll', border: '0px'
  },
  modalBackdrop: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '2',
    position: 'fixed',
    willChange: 'opacity',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  
  },
  tableHeader: {
    color: '#FFF',
    backgroundColor: '#15585E'
  },
  tableStripedRow: {
    "&:nth-child(even)": {
      backgroundColor: '#EEEDF8'
    },
  },
  closeButton: {
    marginTop: '20px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#15585e',
      '& > span': {
        color: '#fff',
      },
    },
    '& > span': {
      color: '#15585e',
    },
  },
  closeButtonTop: {
    float: 'right',
    positon: 'static',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#15585e',
      '& > span': {
        color: '#fff',
      },
    },
    '& > span': {
      color: '#15585e',
    },
  },
  col6:{
    width: '50%', 
    display: 'inline-block'
  }
});


  class TransfereeModal extends Component {
    constructor(props) {
      super(props);
      
      this.state = {      
        modalHeight: '0',
        modalWidth: '0',
      };
    }
  
  componentDidMount() {
    this.setState({
      // modalHeight: this.modal.clientHeight,
      modalWidth: this.modal.clientWidth,
    });
  }
render(){
    return   (
      <div
        // className="MuiModal-root-320"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      > 
      <div
      className={this.props.classes.modalBackdrop}
      aria-hidden="true"
      />
        <div
          className={this.props.classes.modalBackdrop}
          aria-hidden="true"
        />
        <div
          className={this.props.classes.paper}
          ref={(elem) => { this.modal = elem; }}
        >
          <div className={this.props.classes.authHeader}>
            <div className={this.props.classes.col6}>              
              <h2>
                Transferee {this.props.authorizationID}
              </h2>
            </div>
            <div className={this.props.classes.col6}>
              <Button
                  className={this.props.classes.closeButtonTop}
                  onClick={this.props.handleClose}
                  variant="contained"
              >
                Close
              </Button>
            </div>
          </div>       
          <Paper sx={{ width: '100%' }}>
            <SubmittedAuthDetails authorizationID={this.props.authorizationID} readOnly = 'true' isModalRequest = 'true' />
          </Paper>       
          <div className="row justify-space-around">
            <Button
                className={this.props.classes.closeButton}
                onClick={this.props.handleClose}
                variant="contained"
            >
              Close
            </Button>
          </div>          
        </div>  
      </div>
    );
 }
}

TransfereeModal = withStyles(styles)(TransfereeModal);

export default TransfereeModal;