import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import DevTools from '../utilities/devTools';
import api from '../middleware/api';
import benefitApi from 'middleware/selfServeApi';
import rootReducer from '../index.reducer';
import thunk from 'redux-thunk';

const configureStore = (history, preloadedState) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk, api, benefitApi, createLogger()),
      DevTools.instrument(),
    ),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../index.reducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;