import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import { CurrencyFormat } from '../../../common/numberFormatCustom.component';
import { Fragment } from 'react';
import { formatDateWithSlashes } from '../../../utilities/dateFormatter';
import ReadOnlyText from '../../../common/readOnlyText.component';

const styles = (theme) => ({
  cardsContainer: {
    columnCount: 3,
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('md')] : {
      columnCount: 2,
    },
    [theme.breakpoints.down('xs')] : {
      columnCount: 1,
      textAlign: 'center',
    },
  },
  cardContainer: {
    '-webkit-column-break-inside': 'avoid',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  internationalCardContainer: {
    '-webkit-column-break-inside': 'avoid',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  serviceSubHeader: {
    textAlign: 'center'
  },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '70px',
  },
  cardHeaderIntl: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '70px',
    marginBottom: '20px',
  },
  cardIcon: {
    height: '90px',
    width: '90px',
    position: 'absolute',
    top: '-45%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '75px',
      width: '75px',
    },
  },
  cardHeaderText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '7rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
  },
  hhgTabIndicator: {
    backgroundColor: '#43AAA2',
  },
  hhgTabRoot: {
    minWidth: 'unset',
    opacity: 0.3,
  },
  hhgTabRootSelected: {
    // color: theme.palette.primary.light,
    color: '#43AAA2',
    opacity: 1,
  },
});

const boolFormat = (val) => {
  if (val === 'N') return 'No';
  else if (val === 'Y') return 'Yes';
  else return val;
}

const INTERNATIONAL_FIELDS_OVERVIEW_ONE = [
  [
    { key: 'AssignmentStatus', label: 'Assignment Status' },
    { key: 'AssignmentType', label: 'Assignment Type' },
    { key: 'AssignmentCountry', label: 'Assignment Country' },
    { key: 'AssignmentLength', label: 'Assignment Length' },
    { key: 'EstStartDate', label: 'Estimated Start Date', formatter: formatDateWithSlashes },
    { key: 'StartDate', label: 'Start Date', formatter: formatDateWithSlashes },
    { key: 'EstReturnDate', label: 'Estimated Return Date', formatter: formatDateWithSlashes },
    { key: 'ActualReturnDate', label: 'Return Date', formatter: formatDateWithSlashes },
    { key: 'ExtensionDate', label: 'Extension Date', formatter: formatDateWithSlashes },
    { key: 'CompletedDate', label: 'Completed Date', formatter: formatDateWithSlashes },
    { key: 'CancelledDate', label: 'Cancelled Date', formatter: formatDateWithSlashes },
  ]
];

const INTERNATIONAL_FIELDS_OVERVIEW_TWO = [
  [
    { key: 'HomeCountry', label: 'Citizenship' },
    { key: 'SocSecCertExpires', label: 'Certificate Expires', formatter: formatDateWithSlashes },
    { key: 'WorkPermit', label: 'Work Permit', formatter: boolFormat },
    { key: 'WorkPermitAcquired', label: 'Work Permit Acquired', formatter: formatDateWithSlashes },
    { key: 'ResidencyPermit', label: 'Residency Permit', formatter: boolFormat },
    { key: 'ResidencyPermitAcquired', label: 'Res Permit Acquired', formatter: formatDateWithSlashes },
    { key: 'ImmunizationRequired', label: 'Immunization Required', formatter: boolFormat },
    { key: 'ImmunizationFiled', label: 'Immunization Filed', formatter: boolFormat },
    { key: 'ImmunizationFiledDate', label: 'Immunization Filed Date', formatter: formatDateWithSlashes },
    { key: 'Insurance', label: 'Insurance', formatter: boolFormat },
  ]
];

const INTERNATIONAL_FIELDS_COSTS = [
  [
    { key: 'CostEstimateRequested', label: 'Estimate Requested', formatter: formatDateWithSlashes },
    { key: 'CostEstimateCompleted', label: 'Estimate Completed', formatter: formatDateWithSlashes },
    { key: 'CostEstimateRevRequested', label: 'Estimate Rev Requested', formatter: formatDateWithSlashes },
    { key: 'CostEstimateRevCompleted', label: 'Estimate Rev Completed', formatter: formatDateWithSlashes },
    { key: 'PreAssignmentHRConsult', label: 'Pre Assign HR Consult', formatter: formatDateWithSlashes },
  ]
];

const INTERNATIONAL_FIELDS_VISA = [
  [
    { key: 'CurrentVisaStatus', label: 'Current Visa Status' },
    { key: 'VisaExpiration', label: 'Visa Expiration', formatter: formatDateWithSlashes },
    { key: 'VisaMaxDate', label: 'Visa Max Date', formatter: formatDateWithSlashes },
    { key: 'VisaIssuedBy', label: 'Visa Issued By' },
    { key: 'VisaStatus', label: 'Visa Status' },
    { key: 'PassportReceivedDate', label: 'Passport Received Date', formatter: formatDateWithSlashes },
    { key: 'PassportIssuedBy', label: 'Passport Issued By' },
    { key: 'PassportExpires', label: 'Passport Expires', formatter: formatDateWithSlashes },
  ]
];

const HHG_FIELDS = [
  [
    { key: 'EstPackDate', label: 'Estimated Pack Date', formatter: formatDateWithSlashes },
    { key: 'ActualPackDate', label: 'Actual Pack Date', formatter: formatDateWithSlashes },
  ],
  [
    { key: 'EstLoadDate', label: 'Estimated Load Date', formatter: formatDateWithSlashes },
    { key: 'ActualLoadDate', label: 'Actual Load Date', formatter: formatDateWithSlashes },
  ],
  [
    { key: 'EstStorageDate', label: 'Estimated Storage Date', formatter: formatDateWithSlashes },
    { key: 'ActualStorageDate', label: 'Actual Storage Date', formatter: formatDateWithSlashes },
  ],
  [
    { key: 'ActualDateOutOfStorage', label: 'Actual Date Out of Storage', formatter: formatDateWithSlashes },
  ],
  [
    { key: 'EstDeliveryDate', label: 'Estimated Delivery Date', formatter: formatDateWithSlashes },
    { key: 'ActualDeliveryDate', label: 'Actual Delivery Date', formatter: formatDateWithSlashes },
  ],
];

const HHG_FIELD_LIST = HHG_FIELDS.reduce((fields, row) => {
  const keys = row.map((field) => field.key);
  return fields.concat(keys);
}, []);

const hasHhgData = (data) => {
  if (!data) {
    return false;
  }
  return !!HHG_FIELD_LIST.find((key) => !!data[key]);
};

function HhgTab(props) {
  const { data } = props;
  return (
    HHG_FIELDS.map((row, i) => {
      return (
        <Grid container key={i}>
          {row.map((field) => {
            const { formatter, key, label, multiline, size } = field;
            let value = data[key];
            const columnSize = size ? size : 6;

            if (value || typeof formatter === 'function') {
              value = formatter(value);
            }

            return (
              <Grid item xs={columnSize} key={key}>
                <ReadOnlyText
                  label={label}
                  textColor="#ba8a00"
                  value={value || ' '}
                  multiline={multiline}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    })
  );
}

class ServicesCards extends Component {

  state = { hhgSelectedTab: 0 };

  renderHomeSaleCard() {
    const {
      classes,
      submittedAuthDetail: {
        OldAddress1,
        OldAddress2,
        OldCity,
        OldState,
        OldZip,
        ListPrice,
        ListBegins,
        ListEnds,
        OfferAcceptAmt, //contract price
        OfferAcceptDate, // contract date
        OfferExpires, // contract expiration date,
        SchedBuyoutDate, // estimated close date
        ActualBuyoutCloseDate, // actual close date
        BMAAverage,
        ServiceStatuses,
      },
    } = this.props;

    if (ServiceStatuses.HomesaleServiceStatus === null) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #224d88, #4182ca)' }}>
                <FontAwesomeIcon icon="home" color="white" size="4x" />
              </Paper>
              <div className={classes.cardHeaderText}>HOME SALE</div>
            </div>
            {OldAddress1 &&
              <div>
                <Typography className={classes.subtitle}>{OldAddress1}</Typography>
                <Typography className={classes.subtitle}>{OldAddress2}</Typography>
                <Typography className={classes.subtitle}>{`${OldCity  }, ${  OldState  } ${  OldZip}`}</Typography>
              </div>
            }
            <Grid container>
              <Grid item xs={12}>
                <ReadOnlyText
                  label="BMA Average"
                  name="bmaAverage"
                  textColor="#4182ca"
                  value={(BMAAverage) ? BMAAverage : ' '}
                  inputComponent={CurrencyFormat}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Listing Start Date"
                  name="listBegins"
                  textColor="#4182ca"
                  value={(ListBegins) ? formatDateWithSlashes(ListBegins) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Listing End Date"
                  name="listEnds"
                  textColor="#4182ca"
                  value={(ListEnds) ? formatDateWithSlashes(ListEnds) : ' '}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyText
                label="Current List Price"
                name="curListPrice"
                textColor="#4182ca"
                value={(ListPrice) ? ListPrice : ' '}
                inputComponent={CurrencyFormat}
              />
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Contract Date"
                  name="contractDate"
                  textColor="#4182ca"
                  value={(OfferAcceptDate) ? formatDateWithSlashes(OfferAcceptDate) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Contract Price"
                  name="contractPrice"
                  textColor="#4182ca"
                  value={(OfferAcceptAmt) ? OfferAcceptAmt : ' '}
                  inputComponent={CurrencyFormat}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Est. Close Date"
                  name="estCloseDate"
                  textColor="#4182ca"
                  value={(SchedBuyoutDate) ? formatDateWithSlashes(SchedBuyoutDate) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Actual Close Date"
                  name="actCloseDate"
                  textColor="#4182ca"
                  value={(ActualBuyoutCloseDate) ? formatDateWithSlashes(ActualBuyoutCloseDate) : ' '}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <ReadOnlyText
                  label="Expiration Date"
                  name="expirationDate"
                  textColor="#4182ca"
                  value={(OfferExpires) ? formatDateWithSlashes(OfferExpires) : ' '}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </div>
    );
  }

  renderHouseholdGoodsCard_old() {
    const {
      classes,
      submittedAuthDetail: {
        PackActEnd, // actual end pack date
        PackEstEnd, // estimated end pack date
        LoadEstEnd, // est load date
        LoadActEnd, // actual end load date
        StorageEstEnd, // est date into storage
        StorageActStart, // actual date into storage
        StorageActEnd, // actual date out of storage
        DeliveryEstEnd, // est delivery date
        DeliveryActEnd, // actual delivery date
        ServiceStatuses,
      },
    } = this.props;

    if (ServiceStatuses.HHGServiceStatus === null) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #ba8a00, #f0b11d)' }}>
                <FontAwesomeIcon icon="archive" color="white" size="4x" />
              </Paper>
              <div className={classes.cardHeaderText}>HOUSEHOLD GOODS</div>
            </div>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Estimated Pack Date"
                  name="estPackDate"
                  textColor="#ba8a00"
                  value={(PackEstEnd) ? formatDateWithSlashes(PackEstEnd) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Actual Pack Date"
                  name="actPackDate"
                  textColor="#ba8a00"
                  value={(PackActEnd) ? formatDateWithSlashes(PackActEnd) : ' '}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Estimated Load Date"
                  name="estLoadDate"
                  textColor="#ba8a00"
                  value={(LoadEstEnd) ? formatDateWithSlashes(LoadEstEnd) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Actual Load Date"
                  name="actLoadDate"
                  textColor="#ba8a00"
                  value={(LoadActEnd) ? formatDateWithSlashes(LoadActEnd) : ' '}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Est. Storage Date"
                  name="estStoreDate"
                  textColor="#ba8a00"
                  value={(StorageEstEnd) ? formatDateWithSlashes(StorageEstEnd) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Actual Storage Date"
                  name="actStoreDate"
                  textColor="#ba8a00"
                  value={(StorageActStart) ? formatDateWithSlashes(StorageActStart) : ' '}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <ReadOnlyText
                  label="Actual Date Out of Storage"
                  name="actStorageEnd"
                  textColor="#ba8a00"
                  value={(StorageActEnd) ? formatDateWithSlashes(StorageActEnd) : ' '}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Est. Delivery Date"
                  name="actDeliveryDate"
                  textColor="#ba8a00"
                  value={(DeliveryEstEnd) ? formatDateWithSlashes(DeliveryEstEnd) : ' '}
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyText
                  label="Actual Delivery Date"
                  name="estDeliveryDate"
                  textColor="#ba8a00"
                  value={(DeliveryActEnd) ? formatDateWithSlashes(DeliveryActEnd) : ' '}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  handleChange = (event, value) => {
    this.setState({ hhgSelectedTab: value });
  };

  renderHouseholdGoodsCard() {
    const {
      classes,
      submittedAuthDetail: {
        ServiceStatuses,
        HHG,
      },
    } = this.props;

    if (ServiceStatuses.HHGServiceStatus === null) {
      return null;
    }

    const { hhgSelectedTab } = this.state;

    const landData = HHG.find((item) => item.HHGType === 'Land');
    const airData = HHG.find((item) => item.HHGType === 'Air');
    const seaData = HHG.find((item) => item.HHGType === 'Sea');

    const hasLand = hasHhgData(landData);
    const hasAir = hasHhgData(airData);
    const hasSea = hasHhgData(seaData);

    if (!hasLand && !hasAir && !hasSea) {
      return null;
    }

    const selectedTab = hhgSelectedTab ? hhgSelectedTab :
      hasLand ? 'land' :
        hasAir ? 'air' :
          hasSea ? 'sea' : null;

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #ba8a00, #f0b11d)' }}>
                <FontAwesomeIcon icon="box-open" color="white" size="4x" />
              </Paper>
              <div className={classes.cardHeaderText}>HOUSEHOLD GOODS</div>
            </div>
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              variant="fullWidth"
              classes={{ indicator: classes.hhgTabIndicator }}
            >
              {hasLand &&
                <Tab
                  classes={{ root: classes.hhgTabRoot, selected: classes.hhgTabRootSelected }}
                  icon={<FontAwesomeIcon icon="truck-moving" size="2x" />}
                  label="Land"
                  value="land"
                />
              }
              {hasAir &&
                <Tab
                  classes={{ root: classes.hhgTabRoot, selected: classes.hhgTabRootSelected }}
                  icon={<FontAwesomeIcon icon="plane-departure" size="2x" />}
                  label="Air"
                  value="air"
                />
              }
              {hasSea &&
                <Tab
                  classes={{ root: classes.hhgTabRoot, selected: classes.hhgTabRootSelected }}
                  icon={<FontAwesomeIcon icon="ship"  size="2x" />}
                  label="Sea"
                  value="sea"
                />
              }
            </Tabs>
            {selectedTab === 'land' && <HhgTab data={landData} />}
            {selectedTab === 'air' && <HhgTab data={airData} />}
            {selectedTab === 'sea' && <HhgTab data={seaData} />}
          </CardContent>
        </Card>
      </div>
    );
  }

  renderTempLivingCard() {
    const {
      classes,
      submittedAuthDetail: {
        // ServiceStatuses,
        TempLivings,
      },
    } = this.props;

    // if (ServiceStatuses.TempLivingServiceStatus === null) {
    //   return null;
    // }

    // For now, take the latest portion of temp living, in the future we may want to display all of them.
    const tempLivingInfo = (TempLivings && TempLivings.length > 0) ? TempLivings.slice(-1)[0] : null;

    // Confirm the temp living info isn't empty
    if (!tempLivingInfo || (tempLivingInfo && Object.values(tempLivingInfo).every(x => x === null))) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #36939b, #34838a)' }}>
                <FontAwesomeIcon icon="bed" color="white" size="4x" />
              </Paper>
              <div className={classes.cardHeaderText}>TEMP LIVING</div>
            </div>
            {(tempLivingInfo.ComplexAddress1 || tempLivingInfo.ComplexState || tempLivingInfo.ComplexCity) &&
              <div>
                <Typography className={classes.subtitle}>{tempLivingInfo.ComplexAddress1}</Typography>
                <Typography className={classes.subtitle}>{tempLivingInfo.ComplexAddress2}</Typography>
                <Typography className={classes.subtitle}>{`${tempLivingInfo.ComplexCity  }, ${  tempLivingInfo.ComplexState  } ${  tempLivingInfo.ComplexZip}`}</Typography>
              </div>
            }
                <Fragment>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Est. Moved In Date"
                        name="EstMoveInDate"
                        textColor="#36939b"
                        value={(tempLivingInfo.EstMoveInDate) ? formatDateWithSlashes(tempLivingInfo.EstMoveInDate) : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Actual Moved In Date"
                        name="actPurchDate"
                        textColor="#36939b"
                        value={(tempLivingInfo.ActMoveInDate) ? formatDateWithSlashes(tempLivingInfo.ActMoveInDate) : ' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Est. Moved Out Date"
                        name="estPurchDate"
                        textColor="#36939b"
                        value={(tempLivingInfo.EstMoveOutDate) ? formatDateWithSlashes(tempLivingInfo.EstMoveOutDate) : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Actual Moved Out Date"
                        name="actPurchDate"
                        textColor="#36939b"
                        value={(tempLivingInfo.ActMoveOutDate) ? formatDateWithSlashes(tempLivingInfo.ActMoveOutDate) : ' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Days Allowed"
                        name="DaysAllowed"
                        textColor="#36939b"
                        value={(tempLivingInfo.DaysAllowed) ? tempLivingInfo.DaysAllowed : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Days Used"
                        name="DaysUsed"
                        textColor="#36939b"
                        value={(tempLivingInfo.DaysUsed) ? tempLivingInfo.DaysUsed : ' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="# of Adults"
                        name="NAdults"
                        textColor="#36939b"
                        value={(tempLivingInfo.NAdults) ? tempLivingInfo.NAdults : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="# of Children"
                        name="NChildren"
                        textColor="#36939b"
                        value={(tempLivingInfo.NChildren) ? tempLivingInfo.NChildren : ' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="# of Bedrooms"
                        name="NBedrooms"
                        textColor="#36939b"
                        value={(tempLivingInfo.NBedrooms) ? tempLivingInfo.NBedrooms : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="# of Pets"
                        name="NPets"
                        textColor="#36939b"
                        value={(tempLivingInfo.NPets) ? tempLivingInfo.NPets : ' '}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
          </CardContent>
        </Card>
      </div>
    );
  }

  renderDestinationCard() {
    const {
      classes,
      submittedAuthDetail: {
        NewAddress1,
        NewAddress2,
        NewCity,
        NewState,
        NewZip,
        ScheduleClose, // est purchase date
        AcloseDate, // actual purchase date
        PurchasePrice,
        ServiceStatuses,
      },
    } = this.props;

    if (ServiceStatuses.DestBuyerServiceStatus === null && ServiceStatuses.DestRenterServiceStatus === null) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #fd6120, #ef5f2f)' }}>
                <FontAwesomeIcon icon="location-arrow" color="white" size="4x" />
              </Paper>
              <div className={classes.cardHeaderText}>DESTINATION</div>
            </div>
            {(NewAddress1 || NewCity || NewState) &&
              <div>
                <Typography className={classes.subtitle}>{NewAddress1}</Typography>
                <Typography className={classes.subtitle}>{NewAddress2}</Typography>
                <Typography className={classes.subtitle}>{`${NewCity  }, ${  NewState  } ${  NewZip}`}</Typography>
              </div>
            }
            {
              ServiceStatuses.DestBuyerServiceStatus !== null &&
                <Fragment>
                  <Grid container>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Est. Purchase Date"
                        name="estPurchDate"
                        textColor="#fd6120"
                        value={(ScheduleClose) ? formatDateWithSlashes(ScheduleClose) : ' '}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReadOnlyText
                        label="Actual Purchase Date"
                        name="actPurchDate"
                        textColor="#fd6120"
                        value={(AcloseDate) ? formatDateWithSlashes(AcloseDate) : ' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <ReadOnlyText
                        label="Purchase Price"
                        name="purchasePrice"
                        textColor="#fd6120"
                        value={(PurchasePrice) ? PurchasePrice : ' '}
                        inputComponent={CurrencyFormat}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
            }
            
          </CardContent>
        </Card>
      </div>
    );
  }

  renderEmployeeJobCard() {
    const { classes, clientFields } = this.props;

    const filteredClientFields = clientFields.filter((field) => field.UserGroup !== 'AuthOnly');
    if (filteredClientFields.length === 0) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #67a783, #4d8766)' }}>
                <div style={{ marginLeft: '8px', marginTop: '5px' }}>
                  <FontAwesomeIcon icon="edit" color="white" size="4x" />
                </div>
              </Paper>
              <div className={classes.cardHeaderText}>EMPLOYEE JOB INFORMATION</div>
            </div>
            {filteredClientFields.map((field, i) => {
              return (
                <Grid key={i} container>
                  <Grid key={i} item xs={12}>
                    <ReadOnlyText
                      key={i}
                      label={field.FieldLabel}
                      textColor="#67a783"
                      value={field.FieldValue ? field.FieldValue : ' '}
                    />
                  </Grid>
                </Grid>
              );
            })
            }
          </CardContent>
        </Card>
      </div>
    );
  }

  renderInternationalCard() {
    const {
      classes,
      submittedAuthDetail: {
        GlobalAssignment,
        ServiceStatuses,
      },
    } = this.props;

    if (ServiceStatuses.GlobalServiceStatus === null || !GlobalAssignment) {
      return null;
    }

    return (
      <div className={classes.internationalCardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeaderIntl}>
              <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #669999, #51929b)' }}>
                <div style={{ marginLeft: '8px', marginTop: '5px' }}>
                  <FontAwesomeIcon icon="plane" color="white" size="4x" />
                </div>
              </Paper>
              <div className={classes.cardHeaderText}>INTERNATIONAL INFORMATION</div>
            </div>

            <Grid container>
              <Grid item md={8}>
                <h4 className={classes.serviceSubHeader}>Assignment Overview</h4>

                <Grid container spacing={3}>
                  <Grid item md={6}>
                    {INTERNATIONAL_FIELDS_OVERVIEW_ONE.map((row, i) => {
                      return (
                        <Grid container key={i}>
                          {row.map((field) => {
                            const { formatter, key, label, multiline, size } = field;
                            let value = GlobalAssignment[key] ? GlobalAssignment[key] : '-';
                            const columnSize = size ? size : 6;

                            if (typeof formatter === 'function' && value) {
                              value = formatter(value);
                            }

                            return (
                              <Grid item xs={columnSize} key={key}>
                                <ReadOnlyText
                                  label={label}
                                  textColor="#669999"
                                  value={value}
                                  multiline={multiline}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>

                      );
                    })}
                  </Grid>
                  <Grid item md={6}>
                      {INTERNATIONAL_FIELDS_OVERVIEW_TWO.map((row, i) => {
                        return (
                          <Grid container key={i}>
                            {row.map((field) => {
                              const { formatter, key, label, multiline, size } = field;
                              let value = GlobalAssignment[key] ? GlobalAssignment[key] : '-';
                              const columnSize = size ? size : 6;

                              if (typeof formatter === 'function' && value) {
                                value = formatter(value);
                              }

                              return (
                                <Grid item xs={columnSize} key={key}>
                                  <ReadOnlyText
                                    label={label}
                                    textColor="#669999"
                                    value={value}
                                    multiline={multiline}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>

                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item md={4} style={{ borderLeft: '1px solid black'}}>
                  <h4 className={classes.serviceSubHeader}>Cost Estimates</h4>
                  {INTERNATIONAL_FIELDS_COSTS.map((row, i) => {
                    return (
                      <Grid container key={i}>
                        {row.map((field) => {
                          const { formatter, key, label, multiline, size } = field;
                          let value = GlobalAssignment[key] ? GlobalAssignment[key] : '-';
                          const columnSize = size ? size : 6;

                          if (typeof formatter === 'function' && value) {
                            value = formatter(value);
                          }

                          return (
                            <Grid item xs={columnSize} key={key}>
                              <ReadOnlyText
                                label={label}
                                textColor="#669999"
                                value={value}
                                multiline={multiline}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                  <hr/>
                  <h4 className={classes.serviceSubHeader}>Visa and Imigration</h4>
                  {INTERNATIONAL_FIELDS_VISA.map((row, i) => {
                    return (
                      <Grid container key={i}>
                        {row.map((field) => {
                          const { formatter, key, label, multiline, size } = field;
                          let value = GlobalAssignment[key] ? GlobalAssignment[key] : '-';
                          const columnSize = size ? size : 6;

                          if (typeof formatter === 'function' && value) {
                            value = formatter(value);
                          }

                          return (
                            <Grid item xs={columnSize} key={key}>
                              <ReadOnlyText
                                label={label}
                                textColor="#669999"
                                value={value}
                                multiline={multiline}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.renderInternationalCard()}
        <div className={classes.cardsContainer}>
          {this.renderHomeSaleCard()}
          {this.renderTempLivingCard()}
          {this.renderDestinationCard()}
          {this.renderHouseholdGoodsCard()}
          {this.renderEmployeeJobCard()}
        </div>
      </>

    );
  }
}

ServicesCards.propTypes = {
  classes: PropTypes.object.isRequired,
};

ServicesCards = withStyles(styles)(ServicesCards);

export default ServicesCards;