/* eslint-disable array-callback-return */
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { getObjectId, mergeClientAndMasterQuestion } from './versionUtil';
import BenefitQuestionCard from './benefitQuestionCard.component';
import BenefitQuestionDialog from './benefitQuestionDialog.component';
import ConfirmationModal from 'common/confirmModal.component';

const useStyles = makeStyles((theme) => ({
  placeholderContent: {},
}));

export const BenefitQuestions = (props) => {
  const { version, setVersion, isReadOnly } = props;
  const benefitQuestions= version.questionDetails;

  const [openBenefitQuestionDialog, setOpenBenefitQuestionDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [confirmDeleteCallback, setConfirmDeleteCallback] = useState(null);

  const classes = useStyles(props);

  const editBenefitQuestion = (targetQuestion, newQuestion, vrsn) => {
    const targetQuestionId = getObjectId(targetQuestion);
    const questionIdx = vrsn.questionDetails.findIndex((question) => {
      const questionId = getObjectId(question);
      return questionId === targetQuestionId;
    });
    vrsn.questionDetails.splice(questionIdx, 1, newQuestion);
    setVersion({ ...vrsn });
  };

  return (
    <>
      <div className="px-2 py-2">
        <Grid container justify="space-between" spacing={0}>
          <Grid item className="p2">
            <Typography variant="h5">Benefit Questions</Typography>
          </Grid>
          <Grid item className="p2">
            <Button
              color="primary"
              variant="outlined"
              style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}
              onClick={() => setOpenBenefitQuestionDialog(true)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid className="flex-grow" container spacing={0}>
          {benefitQuestions.length === 0 && (
            <Grid item xs={3} className="row p2">
              <Paper className={classNames('center-container', 'px-1', classes.placeholderContent)} elevation={4}>
                <Typography align="center" variant="h5" color="textSecondary">
                  No Questions Added
                </Typography>
              </Paper>
            </Grid>
          )}
          {benefitQuestions.length > 0 &&
          benefitQuestions.reverse().map((question) => {
            const mergedQuestion = mergeClientAndMasterQuestion(question);

            if (mergedQuestion.text) {
              return (
                <Grid item key={getObjectId(mergedQuestion)} xs={3} className="row p2">
                  <BenefitQuestionCard
                    benefitQuestion={mergedQuestion}
                    onEdit={() => {
                      setOpenBenefitQuestionDialog(true);
                      setSelectedQuestion(question);
                    }}
                    onDelete={() => setConfirmDeleteCallback(() => () => {
                      const targetQuestionId = getObjectId(question);
                      const questionIdx = version.questionDetails.findIndex((questionInList) => {
                        const questionId = getObjectId(questionInList);
                        return questionId === targetQuestionId;
                      });
                      version.questionDetails.splice(questionIdx, 1);
                      setVersion({ ...version, questionDetails: [...version.questionDetails] });
                      setConfirmDeleteCallback(null);
                    })}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      <BenefitQuestionDialog
        open={openBenefitQuestionDialog}
        close={() => {
          setOpenBenefitQuestionDialog(false);
          setSelectedQuestion(null);
        }}
        selectedQuestion={selectedQuestion}
        setBenefitQuestion={(newQuestion) => {
          if (selectedQuestion) {
            editBenefitQuestion(selectedQuestion, newQuestion, version);
          } else {
            setVersion({ ...version, questionDetails: [...benefitQuestions, newQuestion] });
          }
        }}
      />
      {!!confirmDeleteCallback &&
        <ConfirmationModal
          titleText="Delete Question?"
          cancelText="Cancel"
          confirmText="Delete"
          handleClose={() => {
            setConfirmDeleteCallback(null);
          }}
          handleConfirm={confirmDeleteCallback}
          bodyText="Are you sure you'd like to delete this question?"
        />
      }
    </>
  );
};

BenefitQuestions.propTypes = {
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default BenefitQuestions;

