import { memoize } from 'lodash';
import { mergeClientAndMaster } from './versionUtil';

export const getVersionFromSummary = (summary) => {
  return mergeClientAndMaster(summary.mostRecentPublish || summary.draft);
};

export const getVersionFromSummaryById = (summary, versionId) => {
  return [summary.mostRecentPublish || {}, summary.draft || {}]
    .find((version) => version.versionId === versionId);
};

export const sortSummaries = memoize((summaries) => {
  const sorted = [...summaries];
  // sort enabled to the top alphabetically
  sorted.sort((summary1, summary2) => {
    if (summary1.enabled && (summary1.mostRecentPublish || summary1.draft) && (summary2.mostRecentPublish || summary2.draft)) {
      if (!summary2.enabled) {
        return -1;
      } else {
        const sortSummary1First = getVersionFromSummary(summary1).name?.toLowerCase() < getVersionFromSummary(summary2).name?.toLowerCase();
        return sortSummary1First ? -1 : 1;
      }
    } else {
      if (summary2.enabled && (summary1.mostRecentPublish || summary1.draft) && (summary2.mostRecentPublish || summary2.draft)) {
        return 1;
      } else {
        const sortSummary1First = getVersionFromSummary(summary1)?.name?.toLowerCase() < getVersionFromSummary(summary2)?.name?.toLowerCase();
        return sortSummary1First ? -1 : 1;
      }
    }
  });
  return sorted;
});

export const transformSummaryIntoBenefit = (summary) => {
  const benefit = { ...summary };
  delete benefit.draft;
  delete benefit.mostRecentPublish;
  return benefit;
};

export const mergeOptionsWithOptionDetails = (options, optionDetails) => {
  return optionDetails.map((optDetail) => {
    const correspondingOption = options.find((opt) => opt.id === optDetail.optionId);
    return { ...optDetail, ...correspondingOption };
  });
};

export const isMasterBenefit = (summary) => {
  return !!summary.mostRecentPublish && !!summary.mostRecentPublish.masterBenefitId;
};

export const canToggleBenefit = (summary) => {
  if (!summary.mostRecentPublish) {
    return false;
  }
  if (!summary.mostRecentPublish.masterVersion) {
    return true;
  }
  return summary.mostRecentPublish.masterVersion.masterBenefit.enabled;
};

export const canToggleOption = (summary, opt) => {
  const canToggle = summary.enabled;
  if (!opt.masterOption) {
    return canToggle;
  }
  return canToggle && opt.masterOption.enabled;
};


