import { Box, Grid, IconButton, MenuItem, Paper, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { COST_TYPES } from './constants';
import {
  CREATE_BENEFIT_FAILURE,
  CREATE_DRAFT_FAILURE,
  DELETE_BENEFIT_DRAFT_FAILURE,
  DELETE_BENEFIT_FAILURE,
  GET_BENEFIT_FAILURE,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_LOOKUP_TABLES_FAILURE,
  PUBLISH_BENEFIT_FAILURE,
  UPDATE_BENEFIT_VERSION_FAILURE,
} from './benefit.types';
import {
  TOAST_MESSAGE_SEVERITY_ERROR,
  TOAST_MESSAGE_SEVERITY_SUCCESS,
  clearBackButtonPath,
  setBackButtonPath,
  setPageTitle,
  showToast,
} from '../layout/layout.actions';
import { areAllQuestionsEqual, mergeClientAndMaster } from './versionUtil';
import {
  createBenefit,
  createDraft, deleteBenefit,
  deleteBenefitDraft,
  getBenefit,
  getCostLookupApis, getCostLookupByTable,
  getLookupTables,
  publishBenefit,
  updateBenefitVersion,
} from './benefit.actions';
import { getVersionFromSummaryById } from './summaryUtil';
import { isLoadingSelector } from './benefit.selectors';
import AddPhoto from './addPhoto.component';
import BenefitContext from './benefitContext';
import BenefitHistoryDialog from './benefitHistoryDialog.component';
import ConfirmationModal from 'common/confirmModal.component';
import SummaryMetaData from '../../common/summaryMetadata.component';

import { PositiveIntegerFormat } from '../../common/numberFormatCustom.component';
import { usePrevious } from 'common/customHooks';
import { useRefCallback } from 'utilities/formUtils';
import BenefitOptions from './benefitOptions.component';
import BenefitQuestions from './benefitQuestions.component';
import ClientBenefitSelectInput from 'common/form/ClientBenefitSelectInput';
import ClientBenefitTextInput from 'common/form/ClientBenefitTextInput';
import ConflictDialog, {
  CONFLICT_CHANGED_DRAFT_MESSAGE,
  CONFLICT_DRAFT_DELETED_MESSAGE,
  CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
  CONFLICT_DRAFT_EXISTS_MESSAGE,
  CONFLICT_NOT_IN_USE_MESSAGE,
  CONFLICT_PUBLISHED_DRAFT_MESSAGE,
} from 'common/conflictDialog.component';
import FormButtons from 'common/form/formButtons.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import PublishDialog from 'common/form/publishDialog.component';
import VendorQuestions from './vendorQuestions.component';

const costTypes = Object.values(COST_TYPES);

const useStyles = makeStyles((theme) => (
  {
    formRoot: {
      flex: 3,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    buttonLast: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    placeholderContent: {
      height: 300,
      width: 300,
    },
    addPhotoRoot: {
      paddingTop: theme.spacing(3),
    },
    policiesAffectedWarning: {
      color: theme.palette.warning.dark,
    },
    tooltipIcon: {
      position: 'absolute',
      top: 32,
      right: -22,
    },
  }
));

const BenefitForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    history,
    location,
    isLoading,
    lookupTables,
    match,
    costLookupApis,
    setBackButtonPath: propsSetBackButtonPath,
    clearBackButtonPath: propsClearBackButtonPath,
    showToast: propsShowToast,
    setPageTitle: propsSetPageTitle,
    getBenefit: propsGetBenefit,
    getCostLookupByTable: propsGetCostLookupByTable,
  } = props;

  const [benefitSummary, setBenefitSummary] = useState(null);
  const [version, setVersion] = useState({
    masterVersion: {},
    optionDetails: [],
    questionDetails: [],
    vendorQuestionDetails: [],
    costType: '',
  });
  const [mergedVersion, setMergedVersion] = useState({ costType: '' });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [openBenefitHistoryDialog, setOpenBenefitHistoryDialog] = useState(false);
  const [showDeleteDraftConfirmation, setShowDeleteDraftConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasAttemptedPublish, setHasAttemptedPublish] = useState(false);
  const prevHasAttemptedPublish = usePrevious(hasAttemptedPublish);
  const [onCostTypeChangeVersion, setOnCostTypeChangeVersion] = useState(null);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [enableOnPublish, setEnableOnPublish] = useState(false);
  const [conflictHandlingDialog, setConflictHandlingDialog] = useState({
    isOpen: false,
    message: null,
  });
  const [formRef, setFormRef] = useRefCallback();
  const disableConstantFields = !isReadOnly && benefitSummary && !isEmpty(benefitSummary.mostRecentPublish);

  const apiCostLookupOptionFields = useMemo(
    () => ((costLookupApis.find((api) => api.id === mergedVersion.costLookupKey) || { optionFields: [] }).optionFields),
    [costLookupApis, mergedVersion.costLookupKey],
  );
  const apiCostLookupAuthorizationFields = useMemo(
    () => ((costLookupApis.find((api) => api.id === mergedVersion.costLookupKey) || { authorizationFields: [] }).authorizationFields),
    [costLookupApis, mergedVersion.costLookupKey],
  );

  const tableCostLookupOptionFields = useMemo(
    () => ((lookupTables.find((table) => table.id === mergedVersion.costLookupKey) || { optionFields: [] }).optionFields),
    [lookupTables, mergedVersion.costLookupKey],
  );

  const tableCostLookupAuthorizationFields = useMemo(
    () => ((lookupTables.find((table) => table.id === mergedVersion.costLookupKey) || { authorizationFields: [] }).authorizationFields),
    [lookupTables, mergedVersion.costLookupKey],
  );

  const getPoliciesAffectedWarning = useCallback(() => {
    if (!benefitSummary || !version || !benefitSummary.policyNames.length) {
      return null;
    }
    const originalQuestions = benefitSummary.mostRecentPublish.questionDetails;
    if (areAllQuestionsEqual(originalQuestions, version.questionDetails)) {
      return null;
    }
    return (
      <Typography variant="subtitle1" className={classes.policiesAffectedWarning}>
        There are policies that offer this benefit. In order for your changes to take effect, those policies will
        need to be updated. <br />Policies impacted: {benefitSummary.policyNames.join(', ')}
      </Typography>
    );
  }, [benefitSummary, version, classes]);

  const saveDraft = async () => {
    setIsSubmitting(true);
    let action;
    if (version.versionId) {
      action = await props.updateBenefitVersion(version);
    } else {
      action = await props.createBenefit(version, { publish: false, enable: false });
    }
    setIsSubmitting(false);
    if (action.type === CREATE_BENEFIT_FAILURE || action.type === UPDATE_BENEFIT_VERSION_FAILURE) {
      if (action.isCollision) {
        handleSaveCollision(action);
      } else {
        props.showToast('Failed to save benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setVersion((v) => {
        Object.assign(v, action.response);
        return v;
      });
      setIsDirty(false);
      props.showToast('Benefit saved.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const handleSaveCollision = (action) => {
    const isPublishedError = action.messages[0].indexOf('published') >= 0;
    const isDeletedError = action.messages[0].indexOf('deleted') >= 0;
    if (isPublishedError) {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_PUBLISHED_DRAFT_MESSAGE,
      });
    } else if (isDeletedError) {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_DRAFT_DELETED_MESSAGE,
      });
    } else {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_CHANGED_DRAFT_MESSAGE,
      });
    }
  };

  const attemptPublish = () => {
    setHasAttemptedPublish(true);
    let errorMessage;
    if (!isFormValid) {
      errorMessage = 'Please fix errors before publishing';
    } else if (version.optionDetails.length === 0) {
      errorMessage = 'Benefit must have at least one option.';
    } else if (!mergedVersion.imageId) {
      errorMessage = 'Benefit must have an image.';
    } else if (!mergedVersion.optionDetails.every((opt) => opt.imageId)) {
      errorMessage = 'All options must have an image.';
    }
    if (errorMessage) {
      propsShowToast(errorMessage, { severity: TOAST_MESSAGE_SEVERITY_ERROR, userMustDismiss: false });
      return;
    }
    setOpenPublishDialog(true);
  };

  const publish = async () => {
    setIsSubmitting(true);
    let action;
    if (version.versionId) {
      action = await props.publishBenefit(version, enableOnPublish);
    } else {
      action = await props.createBenefit(version, { publish: true, enable: enableOnPublish });
    }
    if (action.type === PUBLISH_BENEFIT_FAILURE || action.type === CREATE_BENEFIT_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        handleSaveCollision(action);
      } else {
        props.showToast('Failed to publish benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsDirty(false);
      history.push('/benefits');
      props.showToast('Benefit published.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const startDraft = async () => {
    setIsSubmitting(true);
    const action = await props.createDraft(benefitSummary.id);
    if (action.type === CREATE_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        setConflictHandlingDialog({
          isOpen: true,
          message: CONFLICT_DRAFT_EXISTS_MESSAGE,
        });
      } else {
        props.showToast('Failed to create a new draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsSubmitting(false);
      history.push(`/benefits/${benefitSummary.id}/v/${action.response.versionId}`);
    }
  };

  const deleteDraft = async () => {
    const handleSuccess = () => {
      setIsDirty(false);
      history.push('/benefits');
      props.showToast('Benefit Draft deleted.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    };
    setIsSubmitting(true);
    setShowDeleteDraftConfirmation(false);
    const response = await props.deleteBenefitDraft(version);
    if (response.type === DELETE_BENEFIT_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (response.isCollision) {
        const isPublishedError = response.messages[0].indexOf('published') >= 0;
        if (isPublishedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
          });
        } else {
          // treat this like a success. the draft has already been deleted
          handleSuccess();
        }
      } else {
        props.showToast('Failed to delete benefit draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      handleSuccess();
    }
  };

  const deleteBenefit = async () => {
    setIsSubmitting(true);
    setShowDeleteDraftConfirmation(false);
    const response = await props.deleteBenefit(benefitSummary.id);
    if (response.type === DELETE_BENEFIT_FAILURE) {
      setIsSubmitting(false);
      props.showToast('Failed to delete benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setIsDirty(false);
      history.push('/benefits');
      props.showToast('Benefit deleted.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const canAddOption = useCallback(() => {
    if (!mergedVersion.costType) {
      return false;
    }
    if (mergedVersion.costType === COST_TYPES.TABLE_LOOKUP) {
      return !!mergedVersion.costLookupKey;
    }
    return true;
  }, [mergedVersion]);

  const checkFormValidity = useCallback(() => {
    if (!formRef || !version) {
      return;
    }
    // the one render after they click publish, dryRun should be false to show the validation errors
    const dryRun = !hasAttemptedPublish || hasAttemptedPublish === prevHasAttemptedPublish;
    // wait a tick to allow form fields to populate on page load
    setTimeout(async () => {
      const formValid = await formRef.isFormValid(dryRun);
      setIsFormValid(formValid);
    });
  }, [version, formRef, hasAttemptedPublish, prevHasAttemptedPublish]);

  useEffect(() => {
    if (!isEmpty(benefitSummary)) {
      setIsDirty(version !== getVersionFromSummaryById(benefitSummary, version.versionId));
    }
  }, [version, benefitSummary]);

  useEffect(() => {
    if (isReadOnly) {
      propsSetBackButtonPath('/benefits');
    } else {
      propsClearBackButtonPath();
    }
  }, [isReadOnly, propsClearBackButtonPath, propsSetBackButtonPath]);

  useEffect(() => {
    (async () => {
      if (isEmpty(lookupTables)) {
        const action = await props.getLookupTables();
        if (action.type === GET_LOOKUP_TABLES_FAILURE) {
          props.showToast('Failed to load lookup tables. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
      if (isEmpty(costLookupApis)) {
        const action = await props.getCostLookupApis();
        if (action.type === GET_COST_LOOKUP_APIS_FAILURE) {
          props.showToast('Failed to load cost lookup apis. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    setMergedVersion(mergeClientAndMaster(version));
  }, [version]);

  useEffect(() => {
    (async () => {
      const isEdit = !!match.params.id;
      if (isEdit) {
        const action = await propsGetBenefit(match.params.id);
        if (action.type === GET_BENEFIT_FAILURE) {
          sessionStorage.setItem('redirectUrl', location.pathname);
          propsShowToast('Failed to load benefit. Please make sure you have the correct client selected.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        const benefitSummary = action.response;
        let v = getVersionFromSummaryById(benefitSummary, match.params.versionId);
        if (!v) {
          if (benefitSummary.mostRecentPublish) {
            history.push(`/benefits/${benefitSummary.id}/v/${benefitSummary.mostRecentPublish.versionId}`);
          } else {
            setConflictHandlingDialog({
              isOpen: true,
              message: CONFLICT_NOT_IN_USE_MESSAGE,
            });
            setTimeout(() => {
              history.push('/benefits');
            }, 5000);
          }
          return;
        } else {
          setBenefitSummary(benefitSummary);
          v.questionDetails = v.questionDetails ? v.questionDetails.filter((q) => !q.question || (q.question.benefitId === v.clientBenefitId)) : [];
          v.vendorQuestionDetails = v.vendorQuestionDetails ? v.vendorQuestionDetails.filter((vq) => (vq.question.benefitId === v.clientBenefitId)) : []; 
          setVersion(v);
          setIsReadOnly(v.isPublished);
        }
      }
      propsSetPageTitle('Client Benefit');
      setIsFormValid(isEdit);
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  useEffect(() => {
    if (mergedVersion.costLookupKey && mergedVersion.costType === COST_TYPES.TABLE_LOOKUP) {
      propsGetCostLookupByTable(mergedVersion.costLookupKey);
    }
  }, [mergedVersion.costLookupKey, mergedVersion.costType, propsGetCostLookupByTable]);

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return (
    <BenefitContext.Provider
      value={{ costUnit: !isEmpty(benefitSummary) ? benefitSummary.client.costUnit : '' }}
    >
      <ValidatorForm ref={setFormRef} onSubmit={() => {}} instantValidate={false}>
        <div className="column">
          <Box my={2} mx={2}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                {!isEmpty(benefitSummary) &&
                  <Grid container spacing={0} alignItems="center">
                    <Grid item>
                      <SummaryMetaData summary={benefitSummary} />
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpenBenefitHistoryDialog(true);
                        }}
                      >
                        <FontAwesomeIcon icon={['fas', 'history']} size="1x" />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item xs={7}>
                <FormButtons
                  summary={benefitSummary}
                  version={version}
                  isSubmitting={isSubmitting}
                  createDraftAction={startDraft}
                  editDraftAction={() => history.push(`/benefits/${benefitSummary.id}/v/${benefitSummary.draft.versionId}`)}
                  enableSave={!!mergedVersion.name}
                  enablePublish={!!mergedVersion.name && (!hasAttemptedPublish || isFormValid)}
                  publishAction={() => {
                    setEnableOnPublish(false);
                    attemptPublish();
                  }}
                  publishAndEnableAction={() => {
                    setEnableOnPublish(true);
                    attemptPublish();
                  }}
                  saveAction={saveDraft}
                  deleteAction={() => {setShowDeleteConfirmation(true);}}
                  deleteDraftAction={() => {setShowDeleteDraftConfirmation(true);}}
                  cancelAction={isReadOnly ? null : () => history.push('/benefits')}
                />
              </Grid>
            </Grid>
          </Box>
          <Paper className="mx my">
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="row">
                    <AddPhoto
                      classes={{ cardRoot: classes.addPhotoRoot }}
                      imageId={mergedVersion.imageId}
                      setImageId={(imageId) => setVersion({ ...version, imageId })}
                      setImageUrl={(imageUrl) => setVersion((v) => {v.imageUrl = imageUrl; return v;})}
                      isReadOnly={isReadOnly}
                    />
                    <div className={classNames('p2', classes.formRoot)}>
                      <div className="row">
                        <div className="column mr">
                          <ClientBenefitTextInput
                            clientVersion={version}
                            masterVersion={version.masterVersion}
                            versionField="name"
                            label="Benefit Name"
                            name="benefitName"
                            onChange={(value) => setVersion({ ...version, name: value })}
                            onBlur={(value) => setVersion({ ...version, name: value })}
                            InputProps={{ readOnly: isReadOnly }}
                            validateOnBlur
                            validateOnChange
                            required
                            fullWidth
                          />
                          <ClientBenefitTextInput
                            clientVersion={version}
                            masterVersion={version.masterVersion}
                            versionField="secondaryText"
                            label="Secondary Text"
                            name="benefitSecondaryText"
                            onChange={(value) => setVersion({ ...version, secondaryText: value })}
                            onBlur={(value) => setVersion({ ...version, secondaryText: value })}
                            InputProps={{ readOnly: isReadOnly }}
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            required
                            fullWidth
                          />
                        </div>
                        <div className="column relative">
                          <ClientBenefitSelectInput
                            clientVersion={version}
                            masterVersion={version.masterVersion}
                            versionField="costType"
                            name="benefitCostType"
                            label="Cost Type"
                            onChange={(value) => {
                              if (value !== mergedVersion.costType) {
                                const newVersion = { ...version, costType: value, costLookupKey: '' };
                                if (mergedVersion.costType) {
                                  setVersion({ ...version });
                                  setOnCostTypeChangeVersion(newVersion);
                                } else {
                                  setVersion(newVersion);
                                }
                              }
                            }}
                            disabled={disableConstantFields}
                            InputProps={{ readOnly: isReadOnly }}
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            required
                            fullWidth
                          >
                            {costTypes.map((costType) => (
                              <MenuItem
                                key={costType}
                                value={costType}
                              >
                                {costType}
                              </MenuItem>
                            ))
                            }
                          </ClientBenefitSelectInput>
                          {
                            disableConstantFields &&
                              <Tooltip arrow title="Cost type fields cannot be changed after a benefit has been published.">
                                <div className={classes.tooltipIcon}>
                                  <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                                </div>
                              </Tooltip>
                          }
                          <ClientBenefitSelectInput
                            clientVersion={version}
                            masterVersion={version.masterVersion}
                            versionField="costLookupKey"
                            name="benefitCostLookupKey"
                            label={mergedVersion.costType === COST_TYPES.TABLE_LOOKUP ? 'Table Name' : 'API Name'}
                            onChange={(value) => {
                              if (value !== mergedVersion.costLookupKey) {
                                const newVersion = { ...version, costLookupKey: value };
                                if (mergedVersion.costLookupKey) {
                                  setVersion({ ...version });
                                  setOnCostTypeChangeVersion(newVersion);
                                } else {
                                  setVersion(newVersion);
                                }
                              }
                            }}
                            disabled={disableConstantFields}
                            InputProps={{ readOnly: isReadOnly }}
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            style={{ visibility: mergedVersion.costType === COST_TYPES.TABLE_LOOKUP || mergedVersion.costType === COST_TYPES.API_CALC ? 'visible' : 'hidden' }}
                            required={mergedVersion.costType === COST_TYPES.TABLE_LOOKUP || mergedVersion.costType === COST_TYPES.API_CALC}
                            fullWidth
                          >
                            {mergedVersion.costType === COST_TYPES.TABLE_LOOKUP && lookupTables.map((lookupTable) => (
                              <MenuItem key={lookupTable.id} value={lookupTable.id}>
                                {lookupTable.name}
                              </MenuItem>
                            ))}
                            {mergedVersion.costType === COST_TYPES.API_CALC && (costLookupApis).map((api) => (
                              <MenuItem key={api.id} value={api.id}>
                                {api.name}
                              </MenuItem>
                            ))}
                          </ClientBenefitSelectInput>
                        </div>
                      </div>
                      <ClientBenefitTextInput
                        clientVersion={version}
                        masterVersion={version.masterVersion}
                        versionField="description"
                        label="Description"
                        name="benefitDescription"
                        onChange={(value) => setVersion({ ...version, description: value })}
                        onBlur={(value) => setVersion({ ...version, description: value })}
                        InputProps={{ readOnly: isReadOnly }}
                        validateOnBlur={hasAttemptedPublish}
                        validateOnChange={hasAttemptedPublish}
                        required
                        fullWidth
                        multiline
                        rows={3}
                      />
                      {
                        mergedVersion.costType === COST_TYPES.API_CALC && mergedVersion.costLookupKey &&
                          <div className="row">
                            <div className="column mr">
                              <ClientBenefitTextInput
                                clientVersion={version}
                                masterVersion={version.masterVersion}
                                versionField="minCost"
                                label="Min Cost"
                                name="benefitMinCost"
                                onChange={(value) => setVersion({ ...version, minCost: value })}
                                onBlur={(value) => setVersion({ ...version, minCost: value })}
                                InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                                validateOnBlur={hasAttemptedPublish}
                                validateOnChange={hasAttemptedPublish}
                                fullWidth
                              />
                            </div>
                            <div className="column relative">
                              <ClientBenefitTextInput
                                clientVersion={version}
                                masterVersion={version.masterVersion}
                                versionField="maxCost"
                                label="Max Cost"
                                name="benefitMaxCost"
                                onChange={(value) => setVersion({ ...version, maxCost: value })}
                                onBlur={(value) => setVersion({ ...version, maxCost: value })}
                                InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                                validateOnBlur={hasAttemptedPublish}
                                validateOnChange={hasAttemptedPublish}
                                fullWidth
                              />
                              <Tooltip arrow title={
                                <Typography>Estimate the min and max cost of the benefit. This will help determine what policies it should be offered under.</Typography>
                              }
                              >
                                <div className={classes.tooltipIcon}>
                                  <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Paper className="mx my">
            <BenefitOptions
              version={version}
              mergedVersion={mergedVersion}
              isReadOnly={isReadOnly}
              apiCostLookupOptionFields={apiCostLookupOptionFields}
              apiCostLookupAuthorizationFields={apiCostLookupAuthorizationFields}
              tableCostLookupOptionFields={tableCostLookupOptionFields}
              tableCostLookupAuthorizationFields={tableCostLookupAuthorizationFields}
              canAddOption={canAddOption()}
              setVersion={setVersion}
              hasAttemptedPublish={hasAttemptedPublish}
              classes={{ placeholderContent: classes.placeholderContent }}
            />
          </Paper>
          <Paper className="mx my">
            <BenefitQuestions
              version={version}
              setVersion={setVersion}
              isReadOnly={isReadOnly}
              classes={{ placeholderContent: classes.placeholderContent }}
            />
          </Paper>
          <Paper className="mx my">
            <VendorQuestions
              version={version}
              setVersion={setVersion}
              isReadOnly={isReadOnly}
            />
          </Paper>
        </div>
      </ValidatorForm>

      {benefitSummary && (
        <BenefitHistoryDialog
          open={openBenefitHistoryDialog}
          onClose={() => {
            setOpenBenefitHistoryDialog(false);
          }}
          benefitId={benefitSummary.id}
        />
      )}
      <PublishDialog
        open={openPublishDialog}
        title="Publish Benefit"
        version={version}
        setVersion={setVersion}
        isSubmitting={isSubmitting}
        publishAction={publish}
        cancelAction={() => setOpenPublishDialog(false)}
        warning={getPoliciesAffectedWarning()}
      />
      <Prompt
        when={isDirty}
        message={({ pathname }) => {
          return pathname === props.location.pathname || 'You have unsaved changes, are you sure you want to leave?';
        }}
      />
      {
        showDeleteDraftConfirmation &&
          <ConfirmationModal
            titleText="Delete Draft?"
            cancelText="Cancel"
            confirmText="Delete"
            handleClose={() => {
              setShowDeleteDraftConfirmation(false);
            }}
            handleConfirm={deleteDraft}
            bodyText="Are you sure you'd like to delete this draft?"
          />
      }
      {
        showDeleteConfirmation &&
          <ConfirmationModal
            titleText="Delete Benefit?"
            cancelText="Cancel"
            confirmText="Delete"
            handleClose={() => {
              setShowDeleteConfirmation(false);
            }}
            handleConfirm={deleteBenefit}
            bodyText="Are you sure you'd like to delete this benefit?"
          />
      }
      {onCostTypeChangeVersion &&
        <ConfirmationModal
          handleConfirm={() => {
            setVersion({ ...onCostTypeChangeVersion, optionDetails: [] });
            setOnCostTypeChangeVersion(null);
          }}
          handleClose={() => {
            setOnCostTypeChangeVersion(null);
          }}
          titleText="Change Cost Type Field?"
          confirmText="Confirm"
          cancelText="Cancel"
          bodyText="Changing a cost type field will result in deleting the options for this benefit. Continue?"
        />
      }
      {conflictHandlingDialog.isOpen &&
        <ConflictDialog
          handleConfirm={() => {
            setConflictHandlingDialog({
              isOpen: false,
              message: null,
            });
          }}
          message={conflictHandlingDialog.message}
        />
      }
    </BenefitContext.Provider>
  );
};

BenefitForm.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  lookupTables: PropTypes.array,
  costLookupApis: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  showToast: PropTypes.func.isRequired,
  createDraft: PropTypes.func.isRequired,
  createBenefit: PropTypes.func.isRequired,
  getBenefit: PropTypes.func.isRequired,
  updateBenefitVersion: PropTypes.func.isRequired,
  deleteBenefit: PropTypes.func.isRequired,
  deleteBenefitDraft: PropTypes.func.isRequired,
  publishBenefit: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getLookupTables: PropTypes.func.isRequired,
  getCostLookupApis: PropTypes.func.isRequired,
  getCostLookupByTable: PropTypes.func.isRequired,
  setBackButtonPath: PropTypes.func.isRequired,
  clearBackButtonPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lookupTables: state.benefit.lookupTables,
    isLoading: isLoadingSelector(state),
    costLookupApis: state.benefit.costLookupApis,
  };
};

export default connect(
  mapStateToProps,
  {
    getBenefit,
    createBenefit,
    createDraft,
    publishBenefit,
    updateBenefitVersion,
    deleteBenefit,
    deleteBenefitDraft,
    getLookupTables,
    getCostLookupByTable,
    getCostLookupApis,
    showToast,
    setPageTitle,
    setBackButtonPath,
    clearBackButtonPath,
  })(BenefitForm);
