import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import { dateFormatter } from '../../utilities/dateFormatter';
import FullscreenSpinner from 'common/fullscreenSpinner.component';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const getRowId = (row) => row.AuthorizationID;

class SubmittedAuthorizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'FirstName', title: 'First Name' },
        { name: 'LastName', title: 'Last Name' },
        { name: 'NewCity', title: 'Dest. City' },
        { name: 'NewCountry', title: 'Dest. Country' },
        { name: 'DateAuthorized', title: 'Submitted' },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['DateAuthorized'],
      sorting: [{ columnName: 'DateAuthorized', direction: 'desc' }],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
  }

  TableRow = ({ row, ...restProps }) => {
    return (
      <Table.Row
        {...restProps}
        onClick={() => this.props.handleViewAuthorization(row.AuthorizationID)}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  render() {
    const { columns, pageSize, pageSizes, currentPage, dateColumns, sorting } =
      this.state;
    const { rows, isLoading } = this.props;
    return (
      <div>
        <Paper style={{ padding: '0.5rem' }}>
          <div style={{ padding: '0.3rem', fontSize: '1.25rem' }}>
            Submitted Authorizations
          </div>
          {isLoading ? (
            <FullscreenSpinner />
          ) : (
            <Grid rows={rows} columns={columns} getRowId={getRowId}>
              <DateTypeProvider for={dateColumns} />
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <IntegratedSorting />
              <IntegratedPaging />
              <Table rowComponent={this.TableRow} />
              <TableHeaderRow showSortingControls />
              {rows && rows.length > 5 && <PagingPanel pageSizes={pageSizes} />}
            </Grid>
          )}
        </Paper>
      </div>
    );
  }
}

export default SubmittedAuthorizations;
