import {
  SET_SELF_SERVICE_ERROR_EVENT_ID,
} from './apiError.types';

import produce from 'immer';

const initialState = {
  errorEventId: null,
};


export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_SELF_SERVICE_ERROR_EVENT_ID:
      draft.errorEventId = action.payload;
      break;
    default:
      break;
  }

  return draft;
}, initialState);
