import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormatter } from '../../utilities/dateFormatter';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import {
  Getter,
} from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import FullscreenSpinner from 'common/fullscreenSpinner.component';

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

const VipTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(params) => {
        return params.row.VIP ? <FontAwesomeIcon icon="check" /> : '';
      }}
      {...props}
    />
  );
};

const getRowId = (row) => row.AuthorizationID;

class ApprovalPendingAuthorizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'VIP', title: 'VIP' },
        { name: 'FirstName', title: 'First Name' },
        { name: 'LastName', title: 'Last Name' },
        { name: 'LastUpdated', title: 'Last Updated' },
        { name: 'SubmittedForApprovalOn', title: 'Submitted On' },
        { name: 'SubmittedByFullName', title: 'Submitted By' },
      ],
      tableColumnExtensions: [
        { columnName: 'VIP', width: 100 },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['LastUpdated', 'SubmittedForApprovalOn'],
      vipColumns: ['VIP'],
      sorting: [{ columnName: 'LastUpdated', direction: 'desc' }],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleEditAuthorization, ...restProps }) => {
      return (<Table.Row
        {...restProps}
        onClick={() => props.handleEditAuthorization(row.AuthorizationID)}
        style={{
          cursor: 'pointer',
        }}
              />);
    };

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = this.commitChanges.bind(this);
  }

  // required for editing state
  commitChanges() {

  }

  render() {
    const { rows } = this.props;
    const { columns, pageSize, pageSizes, currentPage, dateColumns, vipColumns, sorting, tableColumnExtensions, isLoading } = this.state;

    return (
      <div>

        <Paper style={{ padding: '1rem' }}>
          <div style={{ padding: '1rem', fontSize: '1.25rem' }}>Authorizations Pending Approval</div>
          {isLoading ? (
            <FullscreenSpinner />
          ) : (
            <Grid
              rows={rows}
              columns={columns}
              getRowId={getRowId}
            >
              <DateTypeProvider
                for={dateColumns}
              />
              <VipTypeProvider
                for={vipColumns}
              />
              <EditingState
                onEditingRowIdsChange={this.changeEditingRowIds}
                onCommitChanges={this.commitChanges}
              />
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <IntegratedSorting />
              <IntegratedPaging />
              <Table rowComponent={this.TableRow} columnExtensions={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <Getter
                name="tableColumns"
                computed={({ tableColumns }) => {
                  return [
                    ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                    { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 150 },
                  ];
                }}
              />
              <PagingPanel
                pageSizes={pageSizes}
              />
            </Grid>
          )}
        </Paper>
      </div >
    );
  }
}

export default ApprovalPendingAuthorizations;