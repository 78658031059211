import Paper from '@material-ui/core/Paper';
import React, { PureComponent } from 'react';

import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  dateFilterPredicate,
  dateFormatter,
} from '../../utilities/dateFormatter';
import { getSelectedClientInformation } from 'utilities/authUtils';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

class Transferees extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns:
        getSelectedClientInformation().id !== '238'
          ? [
            { name: 'EmployeeID', title: 'Employee ID' },
            { name: 'FirstName', title: 'First Name' },
            { name: 'LastName', title: 'Last Name' },
            { name: 'AuthorizationID', title: 'File Number' },
            { name: 'InitiationDate', title: 'Initiation Date' },
            { name: 'NewCity', title: 'Dest City' },
            { name: 'NewState', title: 'Dest State' },
            { name: 'ReloPolicy', title: 'Program Type' },
            //{ name: 'Status', title: 'Status' },
            { name: 'StatusGrouping', title: 'Status'},
          ]
          : [
            { name: 'AuthorizationID', title: 'TRC ID' },
            { name: 'EmployeeID', title: 'Client ID' },
            { name: 'LastName', title: 'Last Name' },
            { name: 'FirstName', title: 'First Name' },
            { name: 'NewJobStartDate', title: 'Effective Date' },
            { name: 'ReloPolicy', title: 'Program Type' },
            //{ name: 'Status', title: 'Status' },
            { name: 'StatusGrouping', title: 'Status'},
            { name: 'NewCity', title: 'Dest Work City' },
            { name: 'NewState', title: 'Dest Work State' },

      ],
      integratedFilteringColumnExtensions: [
        { columnName: 'InitiationDate', predicate: dateFilterPredicate },
        { columnName: 'NewJobStartDate', predicate: dateFilterPredicate },

      ],
      tableColumnExtensions: [
        { columnName: 'EmployeeID', width: 100 },
        { columnName: 'InitiationDate', width: 110 },
        { columnName: 'AuthorizationID', width: 120 },
        { columnName: 'NewCity', width: 150 },
        { columnName: 'NewState', width: 90 },
        { columnName: 'Status', width: 150 },
        { columnName: 'StatusGrouping', width: 150},
      ],
      rows: [],
      currentPage: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50],
      dateColumns: ['InitiationDate', 'NewJobStartDate'],
      sorting: [{ columnName: 'InitiationDate', direction: 'desc' }],
      filters: null,
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.changeFilters = (filters) => {
      this.setState({ filters });
    };

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          onClick={() => props.handleViewSubmittedAuth(row.AuthorizationID)}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };
  }

  render() {
    const { rows, initialFilters } = this.props;
    const {
      columns,
      pageSize,
      pageSizes,
      currentPage,
      dateColumns,
      filters,
      sorting,
      integratedFilteringColumnExtensions,
      tableColumnExtensions,
    } = this.state;
      const useFilters = filters ? filters : initialFilters ? initialFilters : [];

    return (
      <Paper style={{ padding: '1rem' }}>
        {/* <div style={{ padding: '1rem', fontSize: '1.25rem' }}>My Transferees</div> */}
        <Grid rows={rows} columns={columns}>
          <DateTypeProvider for={dateColumns} />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <IntegratedSorting />
          <FilteringState
            filters={useFilters}
            onFiltersChange={this.changeFilters}
          />
          <IntegratedFiltering
            columnExtensions={integratedFilteringColumnExtensions}
          />
          <IntegratedPaging />
          <Table
            rowComponent={this.TableRow}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    );
  }
}

export default Transferees;
