/* eslint-disable array-callback-return */
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CLONE_MASTER_SUCCESS, ENABLE_ALL_SUCCESS, GET_BENEFITS_FAILURE, UPDATE_BENEFIT_FAILURE } from './benefit.types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, setPageTitle, showToast } from 'modules/layout/layout.actions';
import { cloneMasterBenefit, enableAll, getBenefits, updateBenefit } from './benefit.actions';
import { isLoadingSelector } from './benefit.selectors';
import {
  isMasterBenefit,
  sortSummaries,
  transformSummaryIntoBenefit,
} from 'modules/benefits/summaryUtil';
import BenefitCard from './benefitCard.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import MasterBenefitChanges from './masterBenefitChanges.component';

const useStyles = makeStyles((theme) => (
  {
    button: {
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(4),
    },
  }
));

const BenefitsContainer = (props) => {
  const { isLoading, summaries, history } = props;
  const classes = useStyles();

  const persistUpdates = async (summary, errorCallback) => {
    const benefit = transformSummaryIntoBenefit(summary);
    const action = await props.updateBenefit(benefit);
    if (action.type === UPDATE_BENEFIT_FAILURE) {
      errorCallback();
    }
  };

  const enableAll = async () => {
    summaries.forEach((summary) => {
      summary.enabled = true;
      summary.options.forEach((opt) => {
        opt.enabled = true;
      });
    });
    const action = await props.enableAll(summaries);
    if (action.type === ENABLE_ALL_SUCCESS) {
      props.showToast('Successfully enabled all benefits.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    } else {
      props.showToast('Failed to enable some benefits.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  };

  useEffect(() => {
    (async () => {
      props.setPageTitle('Client Benefits');
      const action = await props.getBenefits();
      if (action.type === GET_BENEFITS_FAILURE) {
        props.showToast('Failed to load benefits', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    })();
  }, []); // eslint-disable-line

  if (isLoading) {
    return <FullscreenSpinner />;
  }
  return (
    <div className="mx-2">
      <Paper className="my-2 px-2 py-2">
        <Typography variant="subtitle2" color="textSecondary">
          Client Benefits is the list of all benefits you are contractually offering your transferees.
          These benefits can be customized to better match your company’s relocation policy language as you desire.
          Contact your account manager for more information!
        </Typography>
      </Paper>
      <MasterBenefitChanges />
      <Paper className="px-2">
        <div className="row justify-end my-2">
          <Button className={classes.button} size="large" color="primary" onClick={enableAll}>
            Enable All
          </Button>
          <Button className={classes.button} size="large" color="primary" onClick={() => history.push('/benefits/add')}>
            Add
          </Button>
        </div>
        <Grid container spacing={3}>
          {(summaries || []).map((summary) => {
            if (summary && (summary.mostRecentPublish || summary.draft)) {
              return (
                <Grid item className="column-no-grow" key={summary.id}>
                  {
                    isMasterBenefit(summary) &&
                      <BenefitCard
                        summary={summary}
                        onEdit={async () => {
                          const action = await props.cloneMasterBenefit(summary.mostRecentPublish, true);
                          if (action.type === CLONE_MASTER_SUCCESS) {
                            const clientSummary = action.response;
                            history.push(`/benefits/${clientSummary.id}/v/${clientSummary.mostRecentPublish.versionId}`);
                          } else {
                            showToast('Failed to create client benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                          }
                        }}
                        onToggleOption={() => {}}
                        onToggleEnabled={() => {props.cloneMasterBenefit(summary.mostRecentPublish, true);}}
                      />
                  }
                  {
                    !isMasterBenefit(summary) &&
                      <BenefitCard
                        summary={summary}
                        onEdit={
                          (version) => {
                            history.push(`/benefits/${summary.id}/v/${version.versionId}`);
                          }
                        }
                        onToggleOption={
                          (optId, enabled, errorCallback) => {
                            const selectedOption = summary.options.find((opt) => opt.id === optId);
                            selectedOption.enabled = enabled;
                            persistUpdates(summary, errorCallback);
                          }
                        }
                        onToggleEnabled={
                          (enabled, errorCallback) => {
                            summary.enabled = !summary.enabled;
                            summary.options.forEach((opt) => {
                              opt.enabled = enabled;
                            });
                            persistUpdates(summary, errorCallback);
                          }
                        }
                      />
                  }
                </Grid>
              );
            }
          })}
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    summaries: state.benefit.benefitSummaries ? sortSummaries(state.benefit.benefitSummaries).filter((s) => (s.mostRecentPublish || s.draft)) : null,
    isLoading: isLoadingSelector(state),
  };
};

BenefitsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  summaries: PropTypes.array,
  history: PropTypes.object.isRequired,

  getBenefits: PropTypes.func.isRequired,
  enableAll: PropTypes.func.isRequired,
  updateBenefit: PropTypes.func.isRequired,
  cloneMasterBenefit: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { getBenefits, updateBenefit, enableAll, cloneMasterBenefit, setPageTitle, showToast },
)(BenefitsContainer);