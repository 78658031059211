import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import CustomSelect from '../../../common/customSelect.component';

import { validEmail, validPhoneNumber } from '../../../utilities/fieldValidation';

import { emailTypeMap } from './emailTypeMap';
import { phoneTypeMap } from './phoneTypeMap';
import { convertPhoneNumber, getCountryPhoneDialCode } from './utils';

const styles = (theme) => ({
  textField: {
    marginLeft: '6px',
    marginRight: '-5px',
    width: 200,
  },
  menu: {
    width: 200,
  },
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  gridButton: {
    display: 'flex',
    'align-items': 'center',
    position: 'relative',

    '& > button': {
      // width: '93px'
    },
  },
  '@media (max-width: 1440px)': {
    typeItem: {
      width: '100%',
    },
  },
  '@media (min-width: 1441)': {
    typeItem: {
      width: '50%',
    },
  },
  'typeContainer': {
    margin: 0,
  },
});

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneDetails: [],
      phoneOptions: [],
      currentPhoneType: '',
      currentPhoneNumber: '',
      emailDetails: [],
      emailOptions: [],
      currentEmailType: '',
      currentEmail: '',
    };

    this.handlePhoneTypeChange = this.handlePhoneTypeChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handlePhoneNumberAdd = this.handlePhoneNumberAdd.bind(this);
    this.handlePhoneNumberRemove = this.handlePhoneNumberRemove.bind(this);
    this.handleEmailTypeChange = this.handleEmailTypeChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailAdd = this.handleEmailAdd.bind(this);
    this.handleEmailRemove = this.handleEmailRemove.bind(this);
    this.initContactForm = this.initContactForm.bind(this);
    this.getPhoneDetails = this.getPhoneDetails.bind(this);
    this.updatePhoneDetails = this.updatePhoneDetails.bind(this);
    this.removeEmailDetails = this.removeEmailDetails.bind(this);
    this.updateEmailDetails = this.updateEmailDetails.bind(this);
    this.updateCountryCode = this.updateCountryCode.bind(this);

  }

  // this was causing console error due to setState on unmounted component
  componentWillMount(){
    this.initContactForm();
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.initContactForm(nextProps);
  }

  async initContactForm(nextProps) {
    let { OldOfficePhone, OldHomePhone, OldMobile, OldEmail, SpouseEmail, AltEmail } = this.props.formData.selectedAuth;

    if (nextProps) {
      OldOfficePhone = nextProps.formData.selectedAuth.OldOfficePhone;
      OldHomePhone = nextProps.formData.selectedAuth.OldHomePhone;
      OldMobile = nextProps.formData.selectedAuth.OldMobile;
      OldEmail = nextProps.formData.selectedAuth.OldEmail;
      SpouseEmail = nextProps.formData.selectedAuth.SpouseEmail;
      AltEmail = nextProps.formData.selectedAuth.AltEmail;
    }
    if(this.state.phoneDetails.length < 1)
    {      
      if (OldMobile !== '' && OldMobile !== null) {
        await this.updatePhoneDetails({ type: 'Mobile', number: convertPhoneNumber(OldMobile, ''), country: ''});
      } else {
        await this.removePhoneDetails('Mobile');
      }
  
      // phone fields
      if (OldOfficePhone !== '' && OldOfficePhone !== null) {
        await this.updatePhoneDetails({ type: 'Work', number: convertPhoneNumber(OldOfficePhone, ''), country: ''} );
      } else {
        await this.removePhoneDetails('Work');
      }
  
      if (OldHomePhone !== '' && OldHomePhone !== null) {
        await this.updatePhoneDetails({ type: 'Home', number: convertPhoneNumber(OldHomePhone, ''), country: ''});
      } else {
        await this.removePhoneDetails('Home');
      }
    } else {
      if (OldMobile !== '' && OldMobile !== null) {
        await this.updatePhoneDetails({ type: 'Mobile', number: OldMobile, country: this.state.phoneDetails.find(a => a.type === 'Mobile').country});
      } else {
        await this.removePhoneDetails('Mobile');
      }
  
      // phone fields
      if (OldOfficePhone !== '' && OldOfficePhone !== null) {
        await this.updatePhoneDetails({ type: 'Work', number: OldOfficePhone, country: this.state.phoneDetails.find(a => a.type === 'Work').country} );
      } else {
        await this.removePhoneDetails('Work');
      }
  
      if (OldHomePhone !== '' && OldHomePhone !== null) {
        await this.updatePhoneDetails({ type: 'Home', number: OldHomePhone, country: this.state.phoneDetails.find(a => a.type === 'Home').country});
      } else {
        await this.removePhoneDetails('Home');
      }
    }

    // email fields
    if (OldEmail !== '' && OldEmail !== null) {
      await this.updateEmailDetails({ type: 'Work', address: OldEmail });
    } else {
      await this.removeEmailDetails('Work');
    }

    if (SpouseEmail !== '' && SpouseEmail !== null) {
      await this.updateEmailDetails({ type: 'Spouse', address: SpouseEmail });
    } else {
      await this.removeEmailDetails('Spouse');
    }

    if (AltEmail !== '' && AltEmail !== null) {
      await this.updateEmailDetails({ type: 'Personal', address: AltEmail });
    } else {
      await this.removeEmailDetails('Personal');
    }

    // if contact details are empty, provide one of each type to start with, as one of each type is required
    if (this.state.emailDetails.length === 0) {
      this.handleEmailAdd();
    }

    if (this.state.phoneDetails.length === 0) {
      this.handlePhoneNumberAdd();
    }

  }

  removePhoneDetails(type) {
    let existingDetails = this.state.phoneDetails;
    let foundIndex = existingDetails.findIndex((item) => item.type === type);
    let updateDetails = existingDetails.slice(0);

    // do not remove fields that were pre-existing, ie became cleared by backspace
    if (foundIndex > -1 && existingDetails[foundIndex].number !== '') {
      updateDetails.splice(foundIndex, 1);

      this.setState({
        phoneDetails: updateDetails,
      });
    }
  }

  updateCountryCode(countryCode, index) {
    let existingDetails = this.state.phoneDetails;
    let updateDetails = existingDetails.slice(0);

    updateDetails[index].country = countryCode;

    this.setState({
      phoneDetails: updateDetails,
    });
  }

  updatePhoneDetails(details) {
    let existingDetails = this.state.phoneDetails;
    let foundIndex = existingDetails.findIndex((item) => item.type === details.type);
    let updateDetails = existingDetails.slice(0);

    if (foundIndex > -1) {
      updateDetails[foundIndex] = details;
    } else {
      updateDetails.push(details);
    }

    this.setState({
      phoneDetails: updateDetails,
    });
  }

  removeEmailDetails(type) {
    let existingDetails = this.state.emailDetails;
    let foundIndex = existingDetails.findIndex((item) => item.type === type);
    let updateDetails = existingDetails.slice(0);

    // do not remove fields that were pre-existing
    if (foundIndex > -1 && existingDetails[foundIndex].address !== '') {
      updateDetails.splice(foundIndex, 1);

      this.setState({
        emailDetails: updateDetails,
      });
    }
  }

  updateEmailDetails(details) {
    let existingDetails = this.state.emailDetails;
    let foundIndex = existingDetails.findIndex((item) => item.type === details.type);
    let updateDetails = existingDetails.slice(0);

    if (foundIndex > -1) {
      updateDetails[foundIndex] = details;
    } else {
      updateDetails.push(details);
    }

    this.setState({
      emailDetails: updateDetails,
    });
  }

  handleEmailChange = (index) => (event) => {
    const address = event.target.value;

    // copy state object and update
    let updateDetails = this.state.emailDetails.slice();
    updateDetails[index].address = address;

    this.props.handleContactChange({
      emailDetails: updateDetails,
    });
  }

  handleEmailAdd() {
    const { emailDetails } = this.state;

    let nextTypes = emailTypeMap.filter((item) => {
      let foundExisting = emailDetails.find((existingItem) => existingItem.type === item.type);
      return foundExisting ? false : true;
    });

    if (nextTypes.length > 0) {
      let nextType = nextTypes[0].type;
      let emailDetailsUpdate = emailDetails.slice(0);

      emailDetailsUpdate.push({
        type: nextType,
        address: '',
      });

      this.setState({
        emailDetails: emailDetailsUpdate,
      });
    }
  }

  handleEmailRemove = (index) => async (event) => {
    let { emailDetails } = this.state;

    let updateDetails = emailDetails.slice(0);
    updateDetails.splice(index, 1);

    if (emailDetails[index].address !== '') {
      this.props.handleContactChange({
        emailDetails: updateDetails,
      });
    } else {
      this.setState({
        emailDetails: updateDetails,
      });
    }
  }

  handlePhoneTypeChange = (index) => (event) => {
    const phoneType = event.target.value;

    // copy state object and update
    let updateDetails = this.state.phoneDetails.slice();
    updateDetails[index].type = phoneType;

    if (updateDetails[index].number !== '') {
      this.props.handleContactChange({
        phoneDetails: updateDetails,
      });
    } else {
      this.setState({
        phoneDetails: updateDetails,
      });
    }
  }

  handleEmailTypeChange = (index) => (event) => {
    const emailType = event.target.value;

    // copy state object and update
    let updateDetails = this.state.emailDetails.slice();
    updateDetails[index].type = emailType;

    if (updateDetails[index].number !== '') {
      this.props.handleContactChange({
        emailDetails: updateDetails,
      });
    } else {
      this.setState({
        emailDetails: updateDetails,
      });
    }
  }

  handlePhoneNumberChange = (index) => (event) => {
    const number = event.target.value;

    // copy state object and update
    let updateDetails = this.state.phoneDetails.slice();
    updateDetails[index].number = convertPhoneNumber(number, updateDetails[index].country);

    this.props.handleContactChange({
      phoneDetails: updateDetails,
    });
  }

  handlePhoneNumberAdd() {
    const { phoneDetails } = this.state;

    let nextTypes = phoneTypeMap.filter((item) => {
      let foundExisting = phoneDetails.find((existingItem) => existingItem.type === item.type);
      return foundExisting ? false : true;
    });

    if (nextTypes.length > 0) {
      let nextType = nextTypes[0].type;
      let phoneDetailsUpdate = phoneDetails.slice(0);

      phoneDetailsUpdate.push({
        type: nextType,
        number: '',
        country: '',
      });

      this.setState({
        phoneDetails: phoneDetailsUpdate,
      });
    }
  }

  handlePhoneNumberRemove = (index) => async (event) => {
    let { phoneDetails } = this.state;

    let updateDetails = phoneDetails.slice(0);
    updateDetails.splice(index, 1);

    if (phoneDetails[index].number !== '') {
      this.props.handleContactChange({
        phoneDetails: updateDetails,
      });
    } else {
      this.setState({
        phoneDetails: updateDetails,
      });
    }
  }

  getPhoneDetails(auth) {
    let phoneDetails = [];

    phoneTypeMap.forEach((item) => {
      if (auth[item.field] && auth[item.field].length > 0) {
        phoneDetails.push({
          type: item.type,
          number: auth[item.field],
        });
      }
    });

    return phoneDetails;
  }

  getPhoneOptions(baseType) {
    let phoneOptions = [];
    let { phoneDetails } = this.state;

    phoneTypeMap.forEach((item) => {
      let foundPhoneDetail = phoneDetails.find((pdItem) => pdItem.type === item.type);
      if (!foundPhoneDetail) {
        phoneOptions.push(item.type);
      }
    });

    // ensure base type is provided at a minimum
    phoneOptions.push(baseType);

    return phoneOptions;
  }

  getEmailOptions(baseType) {
    let emailOptions = [];
    let { emailDetails } = this.state;

    emailTypeMap.forEach((item) => {
      let foundEmailDetail = emailDetails.find((edItem) => edItem.type === item.type);
      if (!foundEmailDetail) {
        emailOptions.push(item.type);
      }
    });

    // ensure base type is provided at a minimum
    emailOptions.push(baseType);

    return emailOptions;
  }

  render() {
    const { classes, countries, readonly } = this.props;

    const {
      emailDetails,
      phoneDetails,
    } = this.state;
    
    let countryOptions = [];
    if (countries) {
      countryOptions = countries.map((country) => ({
        value: country.CountryCode,
        label: country.Description,
      }));
    }

    return (
      <Paper className={classes.paperSection}>
        <h3>Contact Information</h3>
        <Grid container>
          <Grid item className={classes.typeItem}>
            {phoneDetails.length > 0 &&
              phoneDetails.map((phone, i) => {
                //if country is already set, don't try getting it because it will return '' if no valid number is set
                
                if(!phone.country || phone.country === '') phone.country = getCountryPhoneDialCode(phone.number);
                const { type, country, number } = phone;
                const phoneOptions = this.getPhoneOptions(type);
                return (
                  <Grid key={`${type}${i}-phone`} container>
                    <Grid item>
                      <TextField
                        className={classes.textField}
                        id="PhoneOptions"
                        label="Phone Type"
                        margin="normal"
                        select
                        required
                        value={type}
                        onChange={this.handlePhoneTypeChange(i)}
                        disabled={readonly}
                      >
                        {phoneOptions.map((option, i) => {
                          return (<MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>);
                        })}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <CustomSelect
                        options={countryOptions}
                        placeholder="Country"
                        onChange={(e) => this.updateCountryCode(e, i)}
                        value={country ? country : 'USA'}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        className={classes.textField}
                        id="PhoneNumber"
                        helperText={validPhoneNumber(number)}
                        required
                        label="Phone Number"
                        margin="normal"
                        value={convertPhoneNumber(number, country)}
                        onChange={this.handlePhoneNumberChange(i)}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item className={classes.gridButton}>
                      {i !== 0 && !readonly &&
                        <IconButton
                          style={{ 'marginTop': '14px', 'marginLeft': '6px' }}
                          color="primary"
                          variant="contained"
                          onClick={this.handlePhoneNumberRemove(i)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </IconButton>
                      }
                    </Grid>
                  </Grid>
                );
              })
            }
            <Grid item xs={12}>
              {phoneDetails.length <= phoneTypeMap.length - 1 && !readonly &&
                <Button
                  onClick={this.handlePhoneNumberAdd}
                >Add Phone Number</Button>
              }
            </Grid>
          </Grid>

          <Grid item className={classes.typeItem}>
            {emailDetails.length > 0 &&
              emailDetails.map((email, i) => {
                const { type, address } = email;
                const emailOptions = this.getEmailOptions(type);
                return (
                  <Grid key={`${type}${i}-email`} container>
                    <Grid item>
                      <TextField
                        className={classes.textField}
                        id="EmailOptions"
                        label="Email Type"
                        margin="normal"
                        select
                        required
                        value={type}
                        onChange={this.handleEmailTypeChange(i)}
                        disabled={readonly}
                      >
                        {emailOptions.map((option, i) => {
                          return (<MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>);
                        })}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        className={classes.textField}
                        id="EmailAddress"
                        helperText={validEmail(address)}
                        required
                        label="Email Address"
                        margin="normal"
                        value={address}
                        onChange={this.handleEmailChange(i)}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item className={classes.gridButton}>
                      {i !== 0 && !readonly &&
                        <IconButton
                          style={{ 'marginTop': '14px', 'marginLeft': '6px' }}
                          color="primary"
                          variant="contained"
                          onClick={this.handleEmailRemove(i)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </IconButton>
                      }
                    </Grid>
                  </Grid>
                );
              })
            }
            <Grid item xs={12}>
              {emailDetails.length <= emailTypeMap.length - 1 && !readonly &&
                <Button
                  onClick={this.handleEmailAdd}
                >Add Email Address</Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ContactForm = withStyles(styles)(ContactForm);

export default ContactForm;
