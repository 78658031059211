import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const menuItemStyle = {
  verticalAlign: 'middle',
  height: '100%',
};

const externalLinkStyle = {
  height: 12,
  width: 12,
  position: 'absolute',
  top: 15,
  right: 4,
};

const sideBarMenuItems = [
  {
    name: 'My Dashboard',
    icon: (
      <FontAwesomeIcon icon="tachometer-alt" style={menuItemStyle} size="lg" />
    ),
    path: '/dashboard',
  },
  {
    name: 'My Authorizations',
    icon: <FontAwesomeIcon icon="user-plus" style={menuItemStyle} size="lg" />,
    path: '/authorizations',
    badge: 'numPendingTransferees',
  },
  {
    name: 'MPC Authorizations',
    icon: <FontAwesomeIcon icon="user-chart" style={menuItemStyle} size="lg" />,
    path: '/authorizations',
    requiredPermission: 'navigation:MPCAuthorizations',
  },
  {
    name: 'My Transferees',
    icon: <FontAwesomeIcon icon="random" style={menuItemStyle} size="lg" />,
    path: '/transferees',
  },
  {
    name: 'My Exceptions',
    icon: (
      <FontAwesomeIcon
        icon="exclamation-circle"
        style={menuItemStyle}
        size="lg"
      />
    ),
    path: '/exceptions',
    badge: 'numPendingExceptions',
  },
  {
    name: 'My Reporting',
    icon: <FontAwesomeIcon icon="list-alt" style={menuItemStyle} size="lg" />,
    path: '/reporting',
    requiredPermission: 'reports:viewReports',
  },
  {
    name: 'My Contacts',
    icon: <FontAwesomeIcon icon="users" style={menuItemStyle} size="lg" />,
    path: '/contacts',
  },
  {
    name: 'My Invoices',
    icon: <FontAwesomeIcon icon="list-ul" style={menuItemStyle} size="lg" />,
    path: '/invoices',
  },
  {
    name: 'My Documents',
    icon: <FontAwesomeIcon icon="archive" style={menuItemStyle} size="lg" />,
    path: '/documents',
    requiredPermission: 'documents:read',
  },
  {
    name: 'My Benefits',
    icon: <FontAwesomeIcon icon="file-edit" style={menuItemStyle} size="lg" />,
    path: '/benefits',
    requiredPermission: 'selfService:benefits',
  },
  {
    name: 'My Policies',
    icon: (
      <FontAwesomeIcon icon="file-contract" style={menuItemStyle} size="lg" />
    ),
    path: '/policies',
    requiredPermission: 'selfService:policies',
  },
  {
    name: 'Neighborhood Scout',
    icon: <FontAwesomeIcon icon="male" style={menuItemStyle} size="lg" />,
    iconRight: (
      <FontAwesomeIcon icon="external-link-alt" style={externalLinkStyle} />
    ),
    url: '/neighborhoodscout',
  },
  {
    name: 'Living Abroad',
    icon: <FontAwesomeIcon icon="plane" style={menuItemStyle} size="lg" />,
    iconRight: (
      <FontAwesomeIcon icon="external-link-alt" style={externalLinkStyle} />
    ),
    url: 'https://www.internationalrelocationcenter.com/front_end/?urlid=71742766297559124360',
  },
  {
    name: 'Global Cost Estimator',
    icon: (
      <FontAwesomeIcon icon="dollar-sign" style={menuItemStyle} size="lg" />
    ),
    iconRight: (
      <FontAwesomeIcon icon="external-link-alt" style={externalLinkStyle} />
    ),
    url: 'https://cloud01.ineotech.com/MWA_IneoDemo/',
  },
];

export default sideBarMenuItems;
