import { get, memoize } from 'lodash';

const waitForGoogleMaps = memoize(() => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.google) {
        resolve(true);
        clearInterval(interval);
      }
    }, 100);
  });
});

const memoizedGetDistance = memoize((originAddress, destinationAddress) => {
  return new Promise((resolve, reject) => {
    if (!get(originAddress, 'formattedAddress') || !get(destinationAddress, 'formattedAddress')) {
      reject('Missing origin or destination address');
      return;
    }

    try {
      distanceMatrixService.getDistanceMatrix({
        origins: [originAddress.formattedAddress],
        destinations: [destinationAddress.formattedAddress],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      }, (response, status) => {
        const elementStatus = get(response, 'rows[0].elements[0].status');
        if (status === window.google.maps.DistanceMatrixStatus.OK &&
            elementStatus === window.google.maps.DistanceMatrixElementStatus.OK) {
          resolve(response.rows[0].elements[0].distance);
        } else {
          reject(`DistanceMatrixService status: ${status} elementStatus: ${elementStatus}`);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}, (originAddress, destinationAddress) => {
  return `${originAddress ? originAddress.formattedAddress: ''}|${destinationAddress ? destinationAddress.formattedAddress: ''}`;
});

let distanceMatrixService;
export const getDistance = async (originAddress, destinationAddress) => {
  await waitForGoogleMaps();
  if (!distanceMatrixService) {
    distanceMatrixService = new window.google.maps.DistanceMatrixService();
  }

  return memoizedGetDistance(originAddress, destinationAddress);
};

let autocompleteService;
export const getCityPredictions = async (input, sessionToken) => {
  await waitForGoogleMaps();
  if (!autocompleteService) {
    autocompleteService = new window.google.maps.places.AutocompleteService();
  }

  const useSessionToken = sessionToken ? sessionToken : await getAutocompleteSessionToken();
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions({
      input,
      sessionToken: useSessionToken,
      types: ['(cities)'],
    }, (res) => {
      resolve(res || []);
    });
  });
};

let placesService;
export const getPlaceDetails = async (suggestion, sessionToken) => {
  await waitForGoogleMaps();
  if (!placesService) {
    placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  }

  if (!sessionToken) {
    // sessionToken is local to this function, so not sure why the lint error here
    // eslint-disable-next-line require-atomic-updates
    sessionToken = await getAutocompleteSessionToken();
  }

  return new Promise((resolve) => {
    placesService.getDetails({
      placeId: suggestion.place_id,
      sessionToken,
      fields: ['address_components', 'formatted_address'],
    }, (res, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const city = parseField(res, 'locality');
        const state = parseField(res, 'administrative_area_level_1');
        const country = parseField(res, 'country');
        resolve({
          formattedAddress: res.formatted_address,
          city: city ? city.long_name: null,
          state: state && country.short_name === 'US' && state.short_name.length <=5  ? state.short_name: null,
          country: country ? country.short_name: null,
        });
      } else {
        resolve(null);
      }
    });
  });
};

const parseField = (placeDetails, type) => {
  return placeDetails.address_components.find((addressComponent) => {
    return addressComponent.types.includes(type);
  });
};

export const getAutocompleteSessionToken = async () => {
  await waitForGoogleMaps();
  return new window.google.maps.places.AutocompleteSessionToken();
};
