import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import moment from 'moment';

import {
  getAuthorizationCurrentPrevious,
  getAuthorizationSearchResults,
  getAuthorizationsByState,
  getAuthorizationsPending,
  getAuthorizationsSubmitted,
  getClientContact,
  getExceptionCurrentPrevious,
  getExpenseCurrentPrevious,
  getHomesaleStatusData,
  getStates,
  getSurveyCurrentPrevious,
} from './dashboard.actions';
import { setPageTitle } from '../layout/layout.actions';

import { getSelectedClientInformation } from 'utilities/authUtils';
import Blog from './blog.component';
import ContactCard from './contactCard.component';
import FavoritesList from './favoritesList.component';
import Grid from '@material-ui/core/Grid';
import HeatMap from './heatMap.component';
import HomeSaleChart from './homeSaleChart.component';
import MPCCard from './MPCCard';
import RecentList from './recentList.component';
import StatsCards from './statsCards.component';

const styles = (theme) => ({
  card: {
    height: '225px',
    'min-width': '200px',
    'margin-top': '1rem',
  },
  cardMPC: {
    height: '150px',
    'min-width': '200px',
    'margin-top': '1rem',
  },
});

class DashboardContainer extends PureComponent {
  async componentDidMount() {
    this.props.setPageTitle('My Dashboard');
    this.props.getAuthorizationCurrentPrevious();
    this.props.getAuthorizationSearchResults();
    this.props.getExceptionCurrentPrevious();
    this.props.getExpenseCurrentPrevious();
    this.props.getSurveyCurrentPrevious();
    this.props.getClientContact();
    this.props.getAuthorizationsByState();
    this.props.getHomesaleStatusData();
    this.props.getStates();
  }

  render() {
    const {
      authorizationsYTD,
      activeAuthFiles,
      classes,
      exceptions,
      expenses,
      surveyScore,
      clientContact,
      authorizationsByState,
      contentWidth,
      userInformation,
      homesaleData,
      states,
      authsByStateLoading,
    } = this.props;

    let statsContainerWidth = '100%';

    // adjust stat card container width based on screen size
    // prevents orphaned cards at certain widths
    if (contentWidth >= 1287 && contentWidth < 1487) {
      statsContainerWidth = '60%';
    } else if (contentWidth >= 960 && contentWidth < 1287) {
      statsContainerWidth = '80%';
    }

    const favorites = activeAuthFiles.filter((file) => file.VIP);
    const renderStatsCards = () => {
      return (
        <Grid item xs={12}>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: statsContainerWidth,
              margin: 'auto',
            }}
          >
            <Grid item xs={6} sm={2} className={classes.card}>
              <StatsCards
                color="#67a783"
                data={authorizationsYTD}
                sendTo="authorizations"
                text="Year To Date Authorizations"
                type="auth"
              />
            </Grid>
            <Grid item xs={6} sm={2} className={classes.card}>
              <StatsCards
                color="#ba8a00"
                data={activeAuthFiles.length}
                sendTo="transferees"
                sendToState={{ filterActive: true }}
                text="Active Transferees"
                type="transferees"
              />
            </Grid>
            <Grid item xs={6} sm={2} className={classes.card}>
              <StatsCards
                color="#3d939b"
                data={expenses}
                sendTo="reporting/costreport"
                sendToState={{
                  startDate: moment().startOf('year').format('YYYY-MM-DD'),
                  endDate: moment().format('YYYY-MM-DD'),
                }}
                text="Year To Date Expenses"
                type="expense"
              />
            </Grid>
            <Grid item xs={6} sm={2} className={classes.card}>
              <StatsCards
                color="#4182ca"
                data={exceptions}
                sendTo="exceptions"
                text="Year To Date Exceptions"
                type="excep"
              />
            </Grid>
            {surveyScore && (
              <Grid item xs={6} sm={2} className={classes.card}>
                <StatsCards
                  color="#fd6120"
                  data={surveyScore}
                  sendTo="reporting/evalbytransferee"
                  text="Year To Date Survey Score"
                  type="services"
                />
              </Grid>
            )}
            {clientContact && (
              <Grid item xs={6} sm={2} className={classes.card}>
                <ContactCard contact={clientContact} />
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    };

    return (
      <div style={{ padding: '1rem' }}>
        <Grid container>
          {getSelectedClientInformation().id === '238' && (
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: statsContainerWidth,
                  margin: 'auto',
                }}
              >
                <Grid item xs={6} sm={2} className={classes.cardMPC}>
                  <MPCCard
                    icon={'house-leave'}
                    bgColor={'linear-gradient(45deg,#4D7025,#436322)'}
                    title={'MPC Authorizations'}
                    to={'/reporting/mpcauthorizations'}
                    history={this.props.history}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className={classes.cardMPC}>
                  <MPCCard
                    icon={'chart-line'}
                    bgColor={'linear-gradient(45deg,#67a783,#4d8766)'}
                    title={'MPC Average Cost'}
                    to={'/reporting/mpcaveragecost'}
                    history={this.props.history}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className={classes.cardMPC}>
                  <MPCCard
                    icon={'user-friends'}
                    bgColor={'linear-gradient(45deg,#fd6120,#ef5f2f)'}
                    title={'MPC Diversity Information'}
                    to={'/reporting/mpcdiversityreport'}
                    history={this.props.history}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className={classes.cardMPC}>
                  <MPCCard
                    icon={'sack-dollar'}
                    bgColor={'linear-gradient(45deg,#4D7025,#436322)'}
                    title={'MPC Total Cost'}
                    to={'/reporting/mpccost'}
                    history={this.props.history}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className={classes.cardMPC}>
                  <MPCCard
                    icon={'hand-holding-usd'}
                    bgColor={'linear-gradient(45deg,#4D7025,#436322)'}
                    title={'MPC Transferee Expenses'}
                    to={'/reporting/mpctransfereeexpense'}
                    history={this.props.history}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {getSelectedClientInformation().id === '238' && renderStatsCards()}

          <Grid item xs={12} md={6} style={{ height: '26rem' }}>
            <HeatMap
              authData={authorizationsByState}
              states={states}
              isLoading={authsByStateLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={
              getSelectedClientInformation().id !== '238'
                ? { height: '26rem' }
                : { height: '26rem', paddingTop: '1.3rem' }
            }
          >
            {getSelectedClientInformation().id !== '238' ? (
              <Blog />
            ) : (
              <HomeSaleChart data={homesaleData} />
            )}
          </Grid>
          {getSelectedClientInformation().id !== '238' && renderStatsCards()}
          {/* {homesaleData && */}

          {getSelectedClientInformation().id !== '238' ? (
            <>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                style={{ height: '385px', marginTop: '2rem' }}
              >
                <HomeSaleChart data={homesaleData} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                style={{ height: '385px', marginTop: '2rem' }}
              >
                <RecentList userInformation={userInformation} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                style={{ height: '385px', marginTop: '2rem' }}
              >
                <FavoritesList favorites={favorites} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authorizationsYTD: state.dashboard.get('authorizationsYTD'),
    activeAuthFiles: state.dashboard.get('activeAuthFiles'),
    expenses: state.dashboard.get('expenses'),
    exceptions: state.dashboard.get('exceptions'),
    surveyScore: state.dashboard.get('surveyScore'),
    clientContact: state.dashboard.get('clientContact'),
    authorizationsByState: state.dashboard.get('authorizationsByState'),
    contentWidth: state.layout.get('contentWidth'),
    userInformation: state.layout.get('userInformation'),
    homesaleData: state.dashboard.get('homesaleData'),
    states: state.dashboard.get('states'),
    authsByStateLoading: state.dashboard.get('authsByStateLoading'),
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    getAuthorizationCurrentPrevious,
    getAuthorizationsPending,
    getAuthorizationSearchResults,
    getAuthorizationsSubmitted,
    getExceptionCurrentPrevious,
    getExpenseCurrentPrevious,
    getSurveyCurrentPrevious,
    getClientContact,
    getAuthorizationsByState,
    getHomesaleStatusData,
    getStates,
  }),
)(DashboardContainer);
