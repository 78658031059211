import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import sideBarMenuItems from './sidebarMenuItems';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';

import { 
  GET_INEO_COST_ESTIMATOR_URL_SUCCESS, 
  GET_NS_URL_SUCCESS, 
  getIneoCostEstimatorUrl,
  getNeighborhoodScoutUrl, 
  setMobileDrawerOpen,
  setNumPendingExceptions,
  setNumPendingTransferees,
} from './layout.actions';

import { detectIE } from '../../utilities/browserUtils';
import { getPendingAuthorizations } from '../authorizations/authorizations.actions';
import { getPendingExceptions } from '../exceptions/exceptions.actions';

import { getSelectedClientInformation, getUserName, isPathAuthorized, userHasPermission } from '../../utilities/authUtils';

import FullscreenSpinner from 'common/fullscreenSpinner.component';
import sidebarLogo from '../../images/trc-logo-white-cropped.png';
// const defaultAvatar = require('../../images/defaultAvatar.jpg');

export const drawerWidth = 240;
export const drawerWidthClose = 60;

const sidebarItemColor = '#E4DFE9';
const sidebarSelectedColor = '#43AAA2';

const styles = (theme) => ({
  gridContainer: {
    padding: '0.5rem',
  },
  badge: {
    margin: 'auto',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  badgeRoot: {
    paddingRight: '1rem',
    position: 'unset',
  },
  listItemTextRoot: {
    padding: '0',
  },
  avatar: {
    width: 100,
    height: 100,
    border: `0.4rem solid ${sidebarItemColor}`,
  },
  menuList: {
    overflow: 'auto',
    paddingTop: '1rem',
  },
  drawerHeader: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-ms-overflow-style': 'none',
    // backgroundColor: '#5F615E',
  },
  hr: {
    margin: '0.5rem',
  },
  userName: {
    color: sidebarItemColor,
    marginTop: '0.25rem',
  },
  menuItem: {
    borderRadius: '0.5rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    color: sidebarItemColor,
    position: 'relative',
    overflow: 'visible',
  },
  menuItemSelected: {
    borderRadius: '0.5rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    position: 'relative',
    backgroundColor: sidebarSelectedColor,
    color: sidebarSelectedColor,
    fontWeight: 'bold',
    overflow: 'visible',
    '&:hover': {
      backgroundColor: sidebarSelectedColor,
    },
  },
  menuListIcon: {
    marginLeft: theme.spacing(.25),
    marginRight: theme.spacing(-2),
    color: sidebarItemColor,
  },
});

class SidebarDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPath: null,
      loadingExternalUrl: false,
    };

    this.navigate = this.navigate.bind(this);
  }

  async componentDidMount() {
    const pendingAuthResponse = await this.props.getPendingAuthorizations();
    const pendingExceptionResponse = await this.props.getPendingExceptions();

    let numPendingAuths = 0, numPendingExceptions = 0;

    if (pendingAuthResponse.type === 'GET_PENDING_AUTHORIZATIONS_SUCCESS') {
      numPendingAuths = pendingAuthResponse.response.length || 0;
    }

    if (pendingExceptionResponse.type === 'GET_EXCEPTIONS_SUCCESS') {
      numPendingExceptions = pendingExceptionResponse.response.length || 0;
    }

    this.props.setNumPendingTransferees(numPendingAuths);
    this.props.setNumPendingExceptions(numPendingExceptions);
    
    this.setState({
      selectedPath: this.props.location.pathname,
    });
  }

  openNeighborhoodScoutUrl = async() => {
    let nsUrl = null;
    const nsUrlResponse = await this.props.getNeighborhoodScoutUrl();
    if (nsUrlResponse.type === GET_NS_URL_SUCCESS) {
      nsUrl = nsUrlResponse.response.url || '';
    } 
    window.open(nsUrl, '_blank');
  };

   openIneoCostEstimatorUrl =  async () => {
     let costEstimatorUrl = null;

     const clientInfo = getSelectedClientInformation();
     const userName = getUserName();
     const ineoCostEstimatorUrlResponse = await this.props.getIneoCostEstimatorUrl(clientInfo.id, userName);
     if (ineoCostEstimatorUrlResponse.type === GET_INEO_COST_ESTIMATOR_URL_SUCCESS) {
       costEstimatorUrl = ineoCostEstimatorUrlResponse.response.url || '';
     } 

     window.open(costEstimatorUrl, '_blank');
   };

   // internal router path, or external url
   async navigate(path, url) {
     if (this.props.mobileDrawerOpen) {
       this.props.setMobileDrawerOpen(false);
     }

     if (url) {
       // use url from backend for neighborhood scout
       if (url === '/neighborhoodscout') {
         await this.openNeighborhoodScoutUrl();
       } 
       else if (url === '/ineocostestimator') {
         this.openIneoCostEstimatorUrl();
       } 
       else {
         window.open(url, '_blank');
       }
     }
     else if (path) {
       this.setState({ selectedPath: path });
       this.props.history.push(path);
     }
   }

   render() {
     const { loadingExternalUrl } = this.state;
     const { classes, open, handleDrawerClose, handleDrawerOpen, userName, location, clientDivisions } = this.props;
     const isIE = detectIE();
     let currentPath = `/${  location.pathname.split('/')[1]}`; // get base path only for sidebar selection
     if (currentPath === '/') {
       currentPath = '/dashboard';
     }

     return (
       <div className={classes.drawerHeader}>
         {
           loadingExternalUrl && <FullscreenSpinner />
         }
         <Grid container className={classes.gridContainer}>
           {open &&
             <Grid item xs={9}>
               <div><img src={sidebarLogo} alt="" style={{ maxWidth: '100%' }} /></div>
             </Grid>
           }
           <Grid item xs={3} style={{ textAlign: 'right' }}>
             {open &&
               <IconButton onClick={handleDrawerClose} style={{ color: sidebarItemColor }}>
                 <FontAwesomeIcon icon="outdent" />
               </IconButton>
             }
             {!open &&
               <IconButton onClick={handleDrawerOpen} style={{ color: sidebarItemColor }}>
                 <FontAwesomeIcon icon="indent" />
               </IconButton>
             }
           </Grid>
         </Grid>

         {open &&
           <Grid container align="center">
             <Grid item xs={12}>
               <Avatar className={classes.avatar}>
                 <AccountCircle style={{ height: '120px', width: '120px', marginLeft: isIE ? '-10px' : 'unset' }} />
               </Avatar>
               {userName && <div className={classes.userName}>{userName}</div>}
             </Grid>
           </Grid>
         }

         <MenuList className={classes.menuList}>
           {sideBarMenuItems.map((item, i) => {
             if (getSelectedClientInformation().id === '238' && item.name === 'My Authorizations') {
               return null;
             }

             if (item.requiredPermission){
               if (!userHasPermission(item.requiredPermission, clientDivisions)){
                 return null;
               }
             }
             if (!isPathAuthorized(item.path, clientDivisions)) {
               return null;
             }

             if (!(getSelectedClientInformation().id === '247') && item.name === 'Global Cost Estimator') {
              return null;
            }

             console.log(clientDivisions);


             let badge = this.props[item.badge];
             return (
               <div key={i}>
                 <MenuItem
                   className={currentPath === item.path ? classes.menuItemSelected : classes.menuItem}
                   onClick={() => this.navigate(item.path, item.url)}
                 >
                   <ListItemIcon className={classes.menuListIcon}>
                     {item.icon}
                   </ListItemIcon>
                   {open && badge ?
                     <Badge classes={{ badge: classes.badge, root: classes.badgeRoot }} color="secondary" badgeContent={badge}>
                       <ListItemText primary={
                         <div style={{ color: sidebarItemColor }}>
                           {item.name}
                         </div>}
                       />
                     </Badge>
                     :
                     open && 
                       <ListItemText primary={
                         <div style={{ color: sidebarItemColor, padding: '0' }} >
                           {item.name}
                         </div>}
                       classes={{ root: classes.listItemTextRoot }}
                       />
                   }
                   {open && item.iconRight &&
                    item.iconRight
                   }
                   {!open && item.iconRight &&
                     <ListItemIcon style={{ marginLeft: '-2', color: sidebarItemColor }}>
                       {item.icon}
                     </ListItemIcon>
                   }
                 </MenuItem>
                 {i < sideBarMenuItems.length - 1 &&
                   <hr className={classes.hr} />
                 }
               </div>
             );
           })}
         </MenuList>
       </div>
     );
   }
}

SidebarDrawer = withStyles(styles, { withTheme: true })(SidebarDrawer);

const mapStateToProps = (state) => {
  return {
    mobileDrawerOpen: state.layout.get('mobileDrawerOpen'),
    numPendingTransferees: state.layout.get('numPendingTransferees'),
    numPendingExceptions: state.layout.get('numPendingExceptions'),
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setMobileDrawerOpen,
  getPendingAuthorizations,
  getPendingExceptions,
  setNumPendingTransferees,
  setNumPendingExceptions,
  getNeighborhoodScoutUrl,
  getIneoCostEstimatorUrl,
})(SidebarDrawer));
